import { FC, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  Upload,
  Flex,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Switch,
  type UploadFile,
  message,
} from 'antd';

import { Card, Select } from '@components';
import { IconDownload } from '@assets';
import { getDateRangeByInterval } from '@utils';

import { Schemas } from '@api-client/generated/types';
import countries from '@data/countries.json';

import { useCreateTax, useUpdateTaxById } from '@hooks-api';
import { useAccount } from '@hooks';

import * as S from './styled';

type TaxDto = Schemas.AdminCreateTaxDto;

type TaxDetailsProps = {
  tax: Schemas.Tax | null;
};

const getYearsList = (): string[] => {
  const years = getDateRangeByInterval(
    dayjs().subtract(3, 'year'),
    dayjs(),
    'year'
  );

  return years.map((year) => dayjs(year).format('YYYY'));
};

const getMonthsList = (): string[] => {
  const months = getDateRangeByInterval(
    dayjs().startOf('year'),
    dayjs().endOf('year'),
    'month'
  );

  return months.map((month) => dayjs(month).format('MMMM'));
};

const getQuartersList = (): string[] => {
  const quarters = getDateRangeByInterval(
    dayjs().subtract(3, 'year'),
    dayjs(),
    'quarters'
  );

  return quarters.map(
    (quarter) => `Q${dayjs(quarter).quarter()} ${dayjs(quarter).format('YYYY')}`
  );
};

const TaxDetails: FC<TaxDetailsProps> = ({ tax }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { companyId } = useAccount();

  const [form] = Form.useForm();

  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [periods, setPeriods] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<UploadFile<File> | null>(
    null
  );

  const [createTax, isLoadingCreate] = useCreateTax();
  const [updateTax, isLoadingUpdate] = useUpdateTaxById();

  useEffect(() => {
    if (selectedPeriod) {
      if (selectedPeriod === 'month') {
        setPeriods(getMonthsList());
      }

      if (selectedPeriod === 'quarter') {
        setPeriods(getQuartersList());
      }

      if (selectedPeriod === 'year') {
        setPeriods(getYearsList());
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    const period = searchParams.get('period');

    if (period) {
      if (period === 'year') {
        form.setFieldsValue({
          periodType: 'year',
          period: searchParams.get('year'),
        });
      } else {
        form.setFieldsValue({
          periodType: 'quarter',
          period: searchParams.get('period'),
        });
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (tax) {
      setSelectedPeriod(tax.periodType);

      form.setFieldsValue({
        ...tax,
        dueDate: dayjs(tax.dueDate),
      });
    }
  }, [tax]);

  const handleSubmit = async (values: TaxDto) => {
    const formData = new FormData();

    if (!selectedFile && !tax) {
      return message.open({
        type: 'error',
        content: 'You need to upload a file',
      });
    }

    if (selectedFile) {
      formData.append('file', selectedFile as any);
    }

    formData.append('type', values.type);
    formData.append('periodType', values.periodType);
    formData.append('period', values.period);
    formData.append('dueDate', dayjs(values.dueDate).format('YYYY-MM-DD'));
    formData.append('amount', String(values.amount));
    formData.append('isEstimated', String(values.isEstimated || false));
    formData.append('details[companyName]', values.details.companyName);
    formData.append('details[iban]', values.details.iban);
    formData.append('details[bic]', values.details.bic);
    formData.append('details[countryCode]', values.details.countryCode);
    formData.append('details[addressLine]', values.details.addressLine);
    formData.append('details[reference]', values.details.reference);

    if (tax) {
      updateTax(
        {
          parameter: {
            id: tax.id,
            companyId: companyId!,
          },
          requestBody: formData as any,
        },
        {
          onSuccess: () => {
            message.success('Tax updated successfully');
          },
        }
      );
    } else {
      createTax(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: formData as any,
        },
        {
          onSuccess: (response) => {
            setSelectedFile(null);
            setSelectedPeriod(null);
            setPeriods([]);

            message.success('Tax created successfully');

            navigate(`/taxes/${response.id}`);
          },
        }
      );
    }
  };

  return (
    <S.Form>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <Flex gap={20} vertical>
          <Card>
            <S.Title>Tax information</S.Title>

            {tax ? (
              <>
                {
                  // @ts-expect-error-next-line
                  tax.files.length ? (
                    <S.Download>
                      <S.File>
                        <S.FileName>
                          {
                            // @ts-expect-error-next-line
                            tax.files[0].name
                          }
                        </S.FileName>
                        <a
                          // @ts-expect-error-next-line
                          href={tax.files[0].url}
                          download="file"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="text"
                            icon={<IconDownload />}
                            size="small"
                          />
                        </a>
                      </S.File>
                    </S.Download>
                  ) : null
                }
              </>
            ) : (
              <Form.Item>
                <Upload
                  customRequest={(opts) => setSelectedFile(opts.file as any)}
                  showUploadList={false}
                >
                  <S.Upload>
                    <S.UploadLabel>
                      {selectedFile
                        ? selectedFile?.name
                        : 'Drop file here to upload'}
                    </S.UploadLabel>

                    <Button type="primary" size="large" ghost>
                      {selectedFile ? 'Change file' : 'Select file'}
                    </Button>
                  </S.Upload>
                </Upload>
              </Form.Item>
            )}

            <Row gutter={[24, 4]}>
              <Col span={24}>
                <Form.Item
                  label="Tax type"
                  name="type"
                  rules={[
                    { required: true, message: 'Please select your tax type!' },
                  ]}
                >
                  <Select
                    placeholder="Select type"
                    size="large"
                    options={[
                      {
                        label: 'VAT',
                        value: 'vat',
                      },
                      {
                        label: 'Corporate',
                        value: 'corporate',
                      },
                      {
                        label: 'Social',
                        value: 'social',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Type of period"
                  name="periodType"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your type of period!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select type of period"
                    size="large"
                    options={[
                      {
                        label: 'Month',
                        value: 'month',
                      },
                      {
                        label: 'Quarter',
                        value: 'quarter',
                      },
                      {
                        label: 'Year',
                        value: 'year',
                      },
                    ]}
                    onChange={setSelectedPeriod}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Period"
                  name="period"
                  rules={[
                    { required: true, message: 'Please select your period!' },
                  ]}
                >
                  <Select
                    placeholder="Select period"
                    size="large"
                    options={periods.map((period) => ({
                      label: period,
                      value: period,
                    }))}
                    onChange={setSelectedPeriod}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Due date"
                  name="dueDate"
                  rules={[
                    { required: true, message: 'Please select your due date!' },
                  ]}
                >
                  <DatePicker placeholder="DD.MM.YYYY" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Form.Item
                  label="Amount"
                  name="amount"
                  rules={[
                    { required: true, message: 'Please enter your amount!' },
                  ]}
                >
                  <InputNumber placeholder="Type the amount" size="large" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <S.FormItemSwitch>
                  <Form.Item name="isEstimated" valuePropName="checked" noStyle>
                    <Switch />
                  </Form.Item>
                  Set as an estimated amount
                </S.FormItemSwitch>
              </Col>
            </Row>
          </Card>

          <Card>
            <S.Title>The authorities tax details</S.Title>

            <Row gutter={[24, 4]}>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name={['details', 'countryCode']}
                  rules={[
                    { required: true, message: 'Please select your country!' },
                  ]}
                >
                  <Select
                    placeholder="Country"
                    size="large"
                    options={countries.map((country) => ({
                      label: country.name,
                      value: country.code,
                    }))}
                    optionFilterProp="label"
                    showSearch
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Company name"
                  name={['details', 'companyName']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your company name!',
                    },
                  ]}
                >
                  <Input placeholder="Type company name" size="large" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="IBAN"
                  name={['details', 'iban']}
                  rules={[
                    { required: true, message: 'Please enter your IBAN!' },
                  ]}
                >
                  <Input
                    placeholder="LUXX XXXX XXXX XXXX XXXX XXXX XXXX"
                    size="large"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="BIC"
                  name={['details', 'bic']}
                  rules={[
                    { required: true, message: 'Please enter your BIC!' },
                  ]}
                >
                  <Input placeholder="ABCDEFGH XXX" size="large" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Address"
                  name={['details', 'addressLine']}
                  rules={[
                    { required: true, message: 'Please enter your address!' },
                  ]}
                >
                  <Input
                    placeholder="Start to type the address to select one"
                    size="large"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Reference"
                  name={['details', 'reference']}
                  rules={[
                    { required: true, message: 'Please enter your reference!' },
                  ]}
                >
                  <Input placeholder="Reference" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <S.Submit>
              <Form.Item noStyle>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoadingCreate || isLoadingUpdate}
                >
                  Publish
                </Button>
              </Form.Item>
            </S.Submit>
          </Card>
        </Flex>
      </Form>
    </S.Form>
  );
};

export default TaxDetails;
