import { rgba } from 'emotion-rgba';

import type { ThemeConfig } from 'antd';

export const baseColors = {
  dark: '#000000',
  black: '#081D46',
  primary: '#0157FF',
  secondary: '#5B78A2',
  error: '#EC1717',
  success: '#098D36',
  white: '#FFFFFF',
};

export const textColors = {
  text500: baseColors.black,
  text400: '#3F577A',
  text300: baseColors.secondary,
  text200: '#819BC0',
  textBlue400: '#42619D',
  textGrey: '#898989',
  textLink: baseColors.primary,
  textWarning: baseColors.error,
  textSuccess: baseColors.success,
  text500Inverted: baseColors.white,
  text300Inverted: rgba(baseColors.white, 0.65),
  textDisabled: '#B3BFD0',
};

export const backgroundColors = {
  bgDarkPrimary: '#0A071C',
  bgDarkActive: '#26262D',
  bgDarkBlue: '#022C7E',
  bgYellow: '#FFE48A',
  bgLightYellow: '#FFF3CC',
  bgLightBlue: '#EAEFFF',
  bgBrightBlue: '#E5EBFF',
  bgLightBlue200: '#F7F9FF',
  bgGrey: '#F2F5F9',
  bgDarkGrey: '#E2E7EE',
  bgWarning: '#FFD5D5',
  bgSuccess: 'rgba(0, 146, 6, 0.08)',
  bgWhite: baseColors.white,
};

export const iconsColors = {
  iconBlack: baseColors.black,
  iconLightGrey: baseColors.secondary,
  iconDarkGrey: '#435563',
  iconLineWarning: '#E12020',
  iconLineYellow: '#D48C00',
  iconWarning: '#FFB2B2',
  iconLineSuccess: '#00BB40',
  iconActive: baseColors.primary,
  iconAccent: '#FFFBED',
  iconTag: '#EAEFFF',
  iconWhite: baseColors.white,
  iconWhite40: rgba(baseColors.white, 0.4),
  iconTips: '#D0E0FF',
  iconGreenLight: '#00DA4B',
};

export const strokeColors = {
  strokeBlue: baseColors.primary,
  strokeGrey: '#D0DEF1',
  strokeWarning: '#E12020',
  strokeDarkGrey: '#E2E7EE',
};

export const badgeColors = {
  badgeSuccess: '#D3FFDA',
  badgeInfo: '#FFFBED',
  badgeWarning: 'rgba(238, 0, 0, 0.04)',
  badgeGrey: '#F2F5F9',
  badgeBlue: '#EAEFFF',
  badgeBlue100: '#EAF4FC',
};

export const buttonColors = {
  buttonBlue: baseColors.primary,
  buttonGrey: '#F0F4F9',
  buttonWhite: baseColors.white,
};

export const colors = {
  white: '#ffffff',
  dark: '#1B1C1F',
  black: '#081D46',
  blackSecondary: '#3F577A',
  gray: '#819BC0',
  grayDark: '#5B78A2',
  primary: '#0066FF',
  secondary: '#5B78A2',
  link: '#0157FF',
  bgLight: '#1C2763',
  bgDark: '#141D4D',
  bgGray: '#F7F9FF',
  bgGrayLight: '#F2F5F9',
  bgBrightBlue200: '#E5EBFF',
  success: '#098D36',
  successBg: '#D6F1D7',
  borderSecondary: '#D0DEF1',
  yellow: '#D48C00',
  yellowLight: '#FFFBED',
  red: '#EC1717',
  redLight: '#FEF5F5',
  blueLight: '#EAEFFF',
  greenLight: '#D3FFDA',
  error: '#EC1717',
  ...textColors,
  ...backgroundColors,
  ...iconsColors,
  ...strokeColors,
  ...badgeColors,
  ...buttonColors,
};

export const config: ThemeConfig = {
  token: {
    fontFamily: 'Inter',
    borderRadius: 8,

    colorPrimary: colors.primary,
    colorLink: colors.link,
    colorTextBase: colors.black,
    colorText: colors.black,
    colorSuccess: colors.success,
    colorSuccessBg: colors.successBg,
    colorBgContainer: colors.white,
    colorBgLayout: colors.white,
    colorBorderSecondary: colors.borderSecondary,

    fontSizeHeading1: 36,
  },
  components: {
    Layout: {
      headerBg: colors.bgDarkPrimary,
      siderBg: colors.bgDarkPrimary,
      headerHeight: 72,
      headerPadding: '0 24px',
      headerColor: colors.white,
    },
    Table: {
      headerBg: colors.white,
      headerSplitColor: 'transparent',
      headerBorderRadius: 0,
      rowHoverBg: colors.bgGray,
      cellPaddingBlock: 8,
    },
    Button: {
      fontWeight: 600,
      paddingInlineLG: 18,
      contentFontSizeLG: 14,
    },
    Tag: {
      lineHeightSM: 2.6,
      borderRadiusSM: 20,
      fontSizeSM: 14,
    },
    Input: {
      borderRadiusLG: 6,
      controlHeightLG: 44,
      colorBorder: colors.borderSecondary,
      colorTextPlaceholder: colors.gray,
      inputFontSizeLG: 14,
    },
    InputNumber: {
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.gray,
      borderRadiusLG: 6,
      fontSizeLG: 14,
    },
    DatePicker: {
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.gray,
      borderRadius: 6,
      fontSizeLG: 14,
    },
    Select: {
      colorBorder: colors.borderSecondary,
      colorTextPlaceholder: colors.gray,
      borderRadiusLG: 6,
      fontSizeLG: 14,
    },
    Breadcrumb: {
      separatorColor: colors.grayDark,
      linkColor: colors.grayDark,
      lastItemColor: colors.grayDark,
      separatorMargin: 2,
    },
    Form: {
      labelColor: colors.grayDark,
    },
    Segmented: {
      controlHeightLG: 38,
      itemColor: colors.grayDark,
      trackBg: colors.bgGray,
      itemActiveBg: colors.blueLight,
      itemSelectedBg: colors.blueLight,
      itemSelectedColor: colors.link,
    },
    Modal: {
      colorBgMask: 'rgba(0, 26, 77, 0.6)',
      titleFontSize: 20,
    },
    Pagination: {
      itemActiveBg: colors.primary,
      itemSize: 26,
      borderRadius: 50,
    },
  },
};
