import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Header = styled(Flex)<FlexProps>`
  min-height: 88px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 10px 10px 0 0;
  padding: 16px 30px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
`;
