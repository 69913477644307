import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAccount } from '@hooks';

import * as S from './styled';

const LayoutPublic: FC = () => {
  const navigate = useNavigate();

  const { isLogged } = useAccount();

  useEffect(() => {
    if (isLogged) {
      navigate('/companies');
    }
  }, [isLogged]);

  return (
    <S.Container>
      <Outlet />
    </S.Container>
  );
};

export default LayoutPublic;
