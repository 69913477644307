import { FC } from 'react';

import { FileViewer } from '@entities';

import { IconEyeOff } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type File = Schemas.Document;
type TaskFile = Schemas.TaskFile;
type CompanyFile = Schemas.CompanyFile;

type FilePreviewProps = {
  title?: string;
  file: File | TaskFile | CompanyFile;
};

const FilePreview: FC<FilePreviewProps> = ({ title, file }) => {
  const isTypeImage = file?.mimeType.includes('image');
  const isTypePDF = file?.mimeType.includes('pdf');

  if (!file) {
    return (
      <S.Preview>
        <S.PreviewContent />
      </S.Preview>
    );
  }

  return (
    <S.Preview>
      {title && <S.PreviewTitle>{title}</S.PreviewTitle>}

      <S.PreviewContent>
        {isTypePDF && (
          <FileViewer selectedFile={file} width="437px" height="622px" />
        )}

        {isTypeImage && <img src={file?.url} alt={file?.fileName} />}

        {!isTypePDF && !isTypeImage && (
          <S.Empty>
            <S.EmptyIcon>
              <IconEyeOff width={32} height={32} />
            </S.EmptyIcon>

            <S.EmptyTitle>Preview can't be displayed</S.EmptyTitle>

            <S.EmptyDescription>
              Only PDF files is available for preview
            </S.EmptyDescription>
          </S.Empty>
        )}
      </S.PreviewContent>
    </S.Preview>
  );
};

export default FilePreview;
