import styled from '@emotion/styled';

export const Button = styled.a`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.grayDark};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.a`
  width: 200px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    color: ${({ theme }) => theme.colors.grayDark};
  }
`;
