import { useMemo } from 'react';

import { Params$AdminCompanyFileController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

type CompanyFile = Schemas.CompanyFile;
type Params = Params$AdminCompanyFileController_findAll;

type CompanyFilesProps = {
  params: Params['parameter'];
  config?: any
};

const useGetCompanyFiles = ({ config, params }: CompanyFilesProps) => {
  const {
    data: files,
    loading,
    refetch,
  } = useApiClient<Params, CompanyFile[]>({
    method: 'AdminCompanyFileController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      files,
      loading,
      refetch,
    }),
    [files, loading]
  );
};

export default useGetCompanyFiles;
