import { useMemo } from 'react';

import { Params$AdminTaxController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { QueryOptions, useApiClient } from '@hooks';

type Params = Params$AdminTaxController_findOneById;

type TaxProps = {
  params: Params['parameter'];
  config: QueryOptions<Schemas.Tax>;
};

const useGetTaxById = ({ config, params }: TaxProps) => {
  const {
    data: tax,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Tax>({
    method: 'AdminTaxController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      tax,
      loading,
      refetch,
    }),
    [tax, loading]
  );
};

export default useGetTaxById;
