import styled from '@emotion/styled';

import { type InputProps, type FlexProps, Input, Flex } from 'antd';

export const Container = styled(Flex)<FlexProps>`
  border: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 10px;
  padding: 16px;
`;

export const Field = styled(Input)<InputProps>`
  flex: 1;
  padding-inline: 0;
`;

export const AttachFile = styled.a<{ disabled: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`;
