import { Params$AdminBillingPeriodController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateBillingPeriod = () => {
  return useApiClientMutation<
    Params$AdminBillingPeriodController_create,
    Schemas.BillingPeriod
  >({
    method: 'AdminBillingPeriodController_create',
  });
};

export default useCreateBillingPeriod;
