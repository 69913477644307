import styled from '@emotion/styled';

import { Button, type ButtonProps } from 'antd';

export const FormDetails = styled.div`
  margin-bottom: -24px;
`;

export const ButtonSubmit = styled(Button)<ButtonProps>`
  width: 190px;
  height: 52px;
  font-size: 16px;
  margin-top: 30px;
`;
