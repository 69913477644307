import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

export const Preview = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  padding: 20px 30px 30px 30px;
`;

export const PreviewTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`;

export const PreviewContent = styled.div`
  height: 622px;
  background-color: ${({ theme }) => theme.colors.textDisabled};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  img {
    width: 100%;
    max-height: 100%;
  }
`;

export const Empty = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
`;

export const EmptyIcon = styled.div`
  width: 72px;
  height: 72px;
  background-color: ${({ theme }) => theme.colors.textDisabled};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const EmptyTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
`;

export const EmptyDescription = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  margin-bottom: 24px;
`;
