import { Params$AdminDocumentController_bulkUpdate } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminDocumentController_bulkUpdate;

const useUpdateDocumentsById = () => {
  const files = useApiClientMutation<Params>({
    method: 'AdminDocumentController_bulkUpdate',
  });

  return files;
};

export default useUpdateDocumentsById;
