import styled from '@emotion/styled';

import { Input, type InputProps } from 'antd';

export const Control = styled(Input)<InputProps>`
  border-color: transparent;

  && {
    max-width: 358px;
    background-color: ${({ theme }) => theme.colors.bgGrayLight};
    border-radius: 20px;
    box-shadow: none;
    padding-inline: 24px;

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.bgGrayLight};
    }

    .ui-input-prefix {
      margin-inline-end: 10px;
    }
  }
`;
