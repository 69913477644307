import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Flex } from 'antd';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Company = Schemas.Company;

type TaskItemProps = {
  companies: Company[];
  selectedId: string;
  details: Schemas.Task;
};

const getTopicNameByValue = (topic: string) => {
  switch (topic) {
    case 'taxes':
      return 'Taxes';

    case 'system_error':
      return 'System Error';

    case 'vat':
      return 'VAT';

    case 'payroll':
      return 'Payroll';

    default:
      return '';
  }
};

const getCompanyNameById = (id: string, companies: Company[]) => {
  const company = companies.filter((company) => company.id === id);

  return company.length ? company[0].name : null;
};

const TaskItem: FC<TaskItemProps> = ({ companies, selectedId, details }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleSelectTask = (id: string) => {
    navigate(`/tasks/${id}${search}`);
  };

  return (
    <S.Task
      gap={6}
      selected={selectedId === details.id}
      onClick={() => handleSelectTask(details.id)}
      vertical
    >
      <Flex align="flex-start" justify="space-between" gap={8}>
        <S.Title>{details.title}</S.Title>

        {details.unseenMessagesCount ? (
          <S.Count>{details.unseenMessagesCount}</S.Count>
        ) : null}
      </Flex>

      <Flex align="center">
        <S.CompanyName>
          {getCompanyNameById(details.companyId, companies)}
        </S.CompanyName>

        <S.Type>{getTopicNameByValue(details.topic)}</S.Type>
      </Flex>
    </S.Task>
  );
};

export default TaskItem;
