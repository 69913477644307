
import { Params$AdminIncorporationController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminIncorporationController_updateOneById;

const useUpdateIncorporationById = () => {
  const incorporation = useApiClientMutation<Params>({
    method: 'AdminIncorporationController_updateOneById',
  });

  return incorporation;
};

export default useUpdateIncorporationById;
