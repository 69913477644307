import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { type FlexProps, Flex } from 'antd';

export const Inner = styled(Flex)<FlexProps>`
  flex: 1;
`;

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
`;
