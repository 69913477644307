import apiClient from '@api-client';
import { Params$AdminCompanyFileController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { useCallback, useState } from 'react';

interface UploadStatus {
  isLoading: boolean;
  error: Error | null;
  success: boolean;
  progress: number;
  response?: Schemas.CompanyFile;
}

/**
 * A hook for uploading a file using `FileController_create` method of apiClient.
 * @param companyId - The ID of the company associated with the file upload.
 * @returns The upload status, including response, and a function to initiate the upload.
 */
const useFileUpload = (companyId: string) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    isLoading: false,
    error: null,
    success: false,
    progress: 0,
  });

  const uploadFile = useCallback(
    (file: FormData) => {
      setUploadStatus((prevStatus) => ({
        ...prevStatus,
        isLoading: true,
        error: null,
        success: false,
        progress: 0,
      }));

      const params: Params$AdminCompanyFileController_create = {
        parameter: {
          companyId: companyId,
        },
        requestBody: file as unknown as Schemas.AdminCreateCompanyFileDto,
      };

      const config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadStatus((prevStatus) => ({
            ...prevStatus,
            progress: percentCompleted,
          }));
        },
      };
      apiClient
        .AdminCompanyFileController_create(params, config)
        .then((response) => {
          setUploadStatus((prevStatus) => ({
            ...prevStatus,
            isLoading: false,
            error: null,
            success: true,
            progress: 100,
            response,
          }));
        })
        .catch((error: Error) => {
          setUploadStatus((prevStatus) => ({
            ...prevStatus,
            isLoading: false,
            error,
            success: false,
            progress: 0,
          }));
        });
    },
    [companyId]
  );

  return { uploadStatus, uploadFile };
};

export default useFileUpload;
