import { FC, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import {
  Typography,
  Flex,
  Breadcrumb,
  Form,
  Input,
  Row,
  Col,
  Segmented,
  message,
  Table,
  TableColumnType,
} from 'antd';

import { useAccount } from '@hooks';
import { useGetDocumentById, useUpdateDocumentById } from '@hooks-api';
import {
  GoBack,
  UploadFile,
  ChatWidget,
  Amount,
  StatusTag,
  StatusProcessBar,
  DocumentsStatusTag,
} from '@entities';
import { Card, InfoCell, Loader } from '@components';
import { IconArrowRight } from '@assets';

import { Schemas } from '@api-client/generated/types';

import { format } from 'date-fns';

import * as S from './styled';

type Status = Schemas.Document['adminStatus'];

const { Title } = Typography;

const columns: TableColumnType<Schemas.Transaction>[] = [
  {
    key: 'contact',
    dataIndex: 'contact',
    title: 'Contact',
    render: (contact, record) => (
      <S.TransactionLink to={`/transactions/${record.id}`}>
        {contact?.name}
      </S.TransactionLink>
    ),
  },
  {
    key: 'bookingDate',
    dataIndex: 'bookingDate',
    title: 'Booked',
    render: (bookingDate) => format(bookingDate, 'dd.MM.yyyy'),
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: 'Amount',
    render: (amount, record) => (
      <Amount amount={amount} currencyCode={record.currencyCode} />
    ),
  },
  {
    key: 'details',
    dataIndex: 'details',
    title: 'Purpose of payment',
  },
  {
    key: 'adminStatus',
    dataIndex: 'adminStatus',
    title: 'Status',
    render: (status) => <StatusTag status={status} />,
  },
];

const DocumentDetails: FC = () => {
  const { id: documentId } = useParams();
  const { companyId } = useAccount();

  const [selectedSegment, setSelectedSegment] = useState('Chat');
  const [statusProcess, setStatusProcess] = useState<Status | null>(null);

  const { file, loading } = useGetDocumentById({
    params: {
      id: documentId!,
      companyId: companyId!,
    },
  });

  const [updateFile] = useUpdateDocumentById();

  useEffect(() => {
    if (file) {
      setStatusProcess(file.adminStatus);
    }
  }, [file]);

  const handleUpdateDetails = (field: string, value: string) => {
    if (file) {
      updateFile(
        {
          parameter: {
            id: documentId!,
            companyId: companyId!,
          },
          // @ts-expect-error-next-line
          requestBody: {
            referenceId: file.referenceId,
            adminNotes: file.adminNotes,
            [field]: value,
          },
        },
        {
          onSuccess: () => {
            message.open({
              type: 'success',
              content: 'File information has been successfully updated',
            });
          },
        }
      );
    }
  };

  const handleUpdateStatus = (status: Status) => {
    handleUpdateDetails('adminStatus', status);
    setStatusProcess(status);
  };

  const handleUpdate = useDebouncedCallback((e) => {
    const { value, id } = e.target;

    handleUpdateDetails(id, value);
  }, 800);

  if (!file || loading) {
    return <Loader />;
  }

  return (
    <S.Container>
      <Flex gap={24} vertical>
        <StatusProcessBar
          status={statusProcess!}
          entityType="file"
          entityId={documentId!}
          onPause={() => handleUpdateStatus('paused')}
          onProcess={() => handleUpdateStatus('processing')}
          onFinish={() => handleUpdateStatus('processed')}
          onRequest={() => handleUpdateStatus('requested_changes')}
        />

        <Breadcrumb
          separator={<IconArrowRight />}
          items={[
            {
              title: <Link to="/documents">Documents</Link>,
            },
            {
              title: file.name,
            },
          ]}
        />

        <S.Header>
          <Flex gap={12} vertical>
            <GoBack />

            <Flex align="center" gap={16}>
              <Title>{file.name}</Title>
              <DocumentsStatusTag status={statusProcess!} />
            </Flex>
          </Flex>
        </S.Header>

        <Flex gap={30} vertical>
          <Form
            layout="vertical"
            onChange={handleUpdate}
            colon={false}
            requiredMark={false}
            initialValues={{
              referenceId: file.referenceId,
              adminNotes: file.adminNotes,
            }}
          >
            <Row gutter={[48, 30]}>
              <Col span={15}>
                <Card title="Info">
                  <Row gutter={[30, 30]}>
                    <Col span={12}>
                      <InfoCell label="Type" value={file.type} />
                    </Col>

                    <Col span={12}>
                      <InfoCell
                        label="Contact"
                        value={file.contact?.name || ''}
                      />
                    </Col>

                    <Col span={24}>
                      <UploadFile
                        url={file.url}
                        name={file.name}
                        type={file.mimeType}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={9}>
                <Form.Item name="referenceId" label="Reference ID">
                  <Input placeholder="Add reference ID" size="large" />
                </Form.Item>

                <S.FormItemClear name="adminNotes" label="Notes">
                  <Input.TextArea
                    placeholder="Add note..."
                    rows={5}
                    size="large"
                  />
                </S.FormItemClear>
              </Col>
            </Row>
          </Form>

          <Card title="Transactions">
            <Table
              rowKey={({ id }) => id}
              dataSource={file.transactions || []}
              columns={columns}
              loading={false}
              pagination={false}
              scroll={{ x: 720 }}
            />
          </Card>

          <Card>
            <Segmented
              options={['Chat']}
              value={selectedSegment}
              onChange={setSelectedSegment}
              size="large"
            />

            {selectedSegment === 'Chat' && (
              <ChatWidget entityId={file.id} entityType="file" />
            )}
          </Card>
        </Flex>
      </Flex>
    </S.Container>
  );
};

export default DocumentDetails;
