import type { PartialDeep } from 'type-fest';

import { Params$AdminOnfidoDataController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useGetOnfidoData = () => {
  return useApiClientMutation<
    PartialDeep<Params$AdminOnfidoDataController_create>,
    Schemas.OnfidoData
  >({
    method: 'AdminOnfidoDataController_create',
  });
};

export default useGetOnfidoData;
