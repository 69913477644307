import { FC, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import _ from 'lodash';

import { Flex, Button, Checkbox } from 'antd';

import { FilterConstructor, TasksList, TaskCreate, TasksChat } from '@entities';
import { Scrollbar, Select } from '@components';

import { useGetTasksList, useGetCompaniesList, useCloseTask } from '@hooks-api';

import { getSearchParams, setSearchParams } from '@utils';
import { IconPlus } from '@assets';

import { useTasksStore } from '@store';

import * as S from './styled';

type FilterOptions = Record<string, unknown>;

const Tasks: FC = () => {
  const { id: taskId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const searchParams = getSearchParams<FilterOptions>(search);

  const [modalCreateVisible, setModalVisible] = useState(false);

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(searchParams);

  const [filterOnlyForUser, setFilterOnlyForUser] = useState<
    boolean | undefined
  >(undefined);

  const [filterCompanyId, setFilterCompanyId] = useState<
    string | null | unknown
  >(null);

  const { fetchTasks, resolveTask } = useTasksStore();

  const { openTasks, closedTasks, selectedTask } = useTasksStore(
    useShallow(({ tasks }) => ({
      openTasks: tasks.filter((task) => !task.closedAt),
      closedTasks: tasks.filter((task) => task.closedAt),
      selectedTask: tasks.filter((task) => task.id === taskId)[0],
    }))
  );

  useGetTasksList({
    params: {
      ...filterOptions,
      onlyForUser: filterOnlyForUser,
      companyId: (!_.isNull(filterCompanyId)
        ? filterCompanyId
        : undefined) as string,
    },
    config: {
      onSuccess: (tasks) => fetchTasks(tasks),
    },
  });

  const { companies } = useGetCompaniesList({
    params: {},
  });

  const [closeTask] = useCloseTask();

  useEffect(() => {
    if (searchParams?.onlyForUser) {
      setFilterOnlyForUser(true);
    }

    if (searchParams?.companyId) {
      setFilterCompanyId(searchParams.companyId);
    }
  }, [search]);

  const handleResolveTask = () =>
    closeTask(
      {
        parameter: {
          id: taskId!,
        },
      },
      {
        onSuccess: (response) => resolveTask(response),
      }
    );

  const handleChangeCompanyId = (value: string) => {
    const params = setSearchParams<FilterOptions>({
      ...searchParams,
      companyId: value,
    });

    setFilterCompanyId(value);

    navigate(taskId ? `/tasks/${taskId}${params}` : `/tasks?${params}`);
  };

  const handleChangeOnlyForUser = (value: boolean | undefined) => {
    const params = setSearchParams<FilterOptions>({
      ...searchParams,
      onlyForUser: value || undefined,
    });

    setFilterOnlyForUser(value || undefined);

    navigate(taskId ? `/tasks/${taskId}${params}` : `/tasks?${params}`);
  };

  const getChatType = () => {
    if (_.some(openTasks, { id: taskId })) {
      return 'open';
    }

    if (_.some(closedTasks, { id: taskId })) {
      return 'closed';
    }

    return 'open';
  };

  const chatType = getChatType();

  return (
    <Flex gap={24} vertical>
      <TaskCreate
        companies={companies}
        open={modalCreateVisible}
        onCancel={() => setModalVisible(false)}
      />

      <Flex align="flex-start" gap={50}>
        <S.Tasks>
          <S.Header gap={32} vertical>
            <Flex align="center" justify="space-between">
              <S.Title>Tasks</S.Title>

              <Flex gap={8}>
                <Button
                  icon={<IconPlus width={24} />}
                  type="secondary"
                  onClick={() => setModalVisible(true)}
                  size="large"
                />

                <Flex>
                  <FilterConstructor
                    controls={{
                      left: [
                        {
                          type: 'module-filter',
                          controls: [
                            {
                              label: 'Topic',
                              type: 'select',
                              formName: 'topic',
                              params: {
                                placeholder: 'Select task topic',
                                options: topicOptions,
                              },
                            },
                          ],
                        },
                      ],
                    }}
                    onChange={setFilterOptions}
                    withSearchParams
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex gap={14} vertical>
              <Select
                value={filterCompanyId}
                options={[
                  {
                    label: 'All companies',
                    value: null,
                  },
                  ...companies.map((company) => ({
                    label: company.name,
                    value: company.id,
                  })),
                ]}
                size="large"
                onChange={handleChangeCompanyId}
                optionFilterProp="label"
                showSearch
              />

              <Checkbox
                checked={filterOnlyForUser}
                onChange={(e) => handleChangeOnlyForUser(e.target.checked)}
              >
                Show only my tasks
              </Checkbox>
            </Flex>
          </S.Header>

          <Scrollbar height="calc(100vh - 400px)" withoutPadding>
            <Flex gap={44} vertical>
              <TasksList
                type="open"
                dataSource={openTasks}
                companies={companies}
              />

              <TasksList
                type="closed"
                dataSource={closedTasks}
                companies={companies}
              />
            </Flex>
          </Scrollbar>
        </S.Tasks>

        <S.Chat>
          <TasksChat
            companies={companies}
            taskId={taskId!}
            type={chatType}
            // @ts-expect-error-next-line
            details={selectedTask}
            onResolve={handleResolveTask}
          />
        </S.Chat>
      </Flex>
    </Flex>
  );
};

export default Tasks;

const topicOptions = [
  {
    label: 'Taxes',
    value: 'taxes',
  },
  {
    label: 'System Error',
    value: 'system_error',
  },
  {
    label: 'VAT',
    value: 'vat',
  },
  {
    label: 'Payroll',
    value: 'payroll',
  },
];
