import { FC } from 'react';

import { type InputProps } from 'antd';

import { IconSearch } from '@assets';
import { colors } from '@theme';

import * as S from './styled';

const SearchControl: FC<InputProps> = ({ ...rest }) => (
  <S.Control
    size="large"
    placeholder="Search"
    prefix={<IconSearch color={colors.gray} />}
    variant="filled"
    {...rest}
  />
);

export default SearchControl;
