import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Item = styled(Typography.Text)`
  min-width: 88px;
  display: block;
`;

export const Button = styled.a`
  &:not(:hover) {
    color: ${({ theme }) => theme.colors.text400};
  }
`;
