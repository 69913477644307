import { Params$AdminDocumentController_runRecognition } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminDocumentController_runRecognition;

const useRunRecognitionDocument = () => {
  const files = useApiClientMutation<Params>({
    method: 'AdminDocumentController_runRecognition',
  });

  return files;
};

export default useRunRecognitionDocument;
