import { Params$AdminTaxController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminTaxController_updateOneById;

const useUpdateTaxById = () => {
  return useApiClientMutation<Params>({
    method: 'AdminTaxController_updateOneById',
  });
};

export default useUpdateTaxById;
