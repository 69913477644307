import { FC, Fragment, useEffect, useState } from 'react';

import { Flex } from 'antd';

import { ChatWidgetForm, ChatWidgetMessage } from '@entities';
import { useAccount, useChat } from '@hooks';
import { useGetChatCommentsList } from '@hooks-api';

import { Parameter$AdminCommentController_findAll } from '@api-client/generated/client';

import * as S from './styled';

type Parameters = Parameter$AdminCommentController_findAll;

export type ChatWidgetProps = {
  entityId: string;
  entityType: Parameters['entityType'];
};

const ChatWidget: FC<ChatWidgetProps> = ({ entityId, entityType }) => {
  const { chatComments, initialComments } = useChat();
  const { account, companyId } = useAccount();

  const { comments, loading } = useGetChatCommentsList({
    params: {
      companyId: companyId!,
      entityType,
      entityId,
    },
  });

  const [replyId, setReplyId] = useState<string | null>(null);

  useEffect(() => {
    if (comments.length) {
      initialComments(comments);
    }
  }, [comments]);

  useEffect(() => {
    return () => {
      initialComments([]);
    };
  }, []);

  return (
    <S.Container>
      {!loading ? (
        <>
          {(chatComments || []).map(({ childComments, ...comment }) => (
            <Fragment key={comment.id}>
              <ChatWidgetMessage
                comment={comment}
                onReply={() => setReplyId(comment.id)}
                hideReply={replyId === comment.id}
                isParent
              />

              {childComments?.length ? (
                <S.ChildWrapper>
                  <Flex gap={2} vertical>
                    {childComments.map((child) => (
                      <Fragment key={child.id}>
                        <ChatWidgetMessage
                          comment={child}
                          onReply={() => setReplyId(child.id)}
                          isParent={false}
                          hideReply
                        />
                      </Fragment>
                    ))}
                  </Flex>
                </S.ChildWrapper>
              ) : null}

              {replyId === comment.id && (
                <S.FormReply>
                  <ChatWidgetForm
                    avatar={account?.email || ''}
                    entityId={entityId}
                    entityType={entityType}
                    parentId={replyId}
                    severity="normal"
                  />
                </S.FormReply>
              )}
            </Fragment>
          ))}
        </>
      ) : null}

      <ChatWidgetForm
        avatar={account?.email || ''}
        entityId={entityId}
        entityType={entityType}
        severity="normal"
      />
    </S.Container>
  );
};

export default ChatWidget;
