import { type ReactNode, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs, { type Dayjs } from 'dayjs';
import _ from 'lodash';

import { type TableColumnType, Form, Flex } from 'antd';

import FilterConstructorControls, {
  type FilterControl,
  type FilterSearchParams,
  type FilterResetParams,
} from './FilterConstructorControls';

import { useSavedColumns } from '@hooks';

import { getNormalizePathname, getSearchParams, setSearchParams } from '@utils';

import * as S from './styled';

type FilterConstructorProps<TColumn> = {
  // List of controls, the order in the array is responsible for positioning
  controls: {
    left?: FilterControl[]; // Controls will be placed on the left side
    right?: FilterControl[]; // Controls will be placed on the right side
  };
  // If the filter module is outside the constructor
  filterControls?: string[];
  // Additional custom events
  actions?: ReactNode;
  // List of table columns, type transfer is indicated in the example
  columns?: TableColumnType<TColumn>[];
  // Additional parameters from external modules
  externalParameters?: FilterSearchParams | string[] | null | FilterResetParams;
  // Enabling search query params
  withSearchParams?: boolean;
  // API request for re-request with filter parameters
  onRequest?: () => void;
  // Returns the values selected in the filter
  onChange: (values: FilterSearchParams) => void;
  // Returns the filtered columns
  onChangeColumns?: (columns: TableColumnType<TColumn>[]) => void;
  // Check that all parameters have been received and are ready to be passed to the request
  // Eliminates the occasional empty request
  onReady?: (status: boolean) => void;
};

/**
 * @description Universal constructor for filtering
 *
 * @example
 *
 * <FilterConstructor<TColumn>
 *   controls={{
 *     left: [
 *       {
 *         type: 'search',
 *         formName: 'term',
 *       },
 *       {
 *         type: 'range-picker',
 *         formName: 'date',
 *       },
 *       {
 *         type: 'list-types',
 *         formName: 'type',
 *       },
 *     ],
 *     right: []
 *   }}
 *   actions={<div>...</div>}
 *   onRequest={refetchApiRequest}
 *   onChange={handleChangeFilter}
 *   onReady={handleReady}
 *   withSearchParams
 * />
 *
 */

const FilterConstructor = <TColumn,>({
  controls,
  actions,
  columns,
  filterControls,
  externalParameters,
  withSearchParams,
  onRequest,
  onChange,
  onChangeColumns,
  onReady,
}: FilterConstructorProps<TColumn>) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [form] = Form.useForm();

  const [filterSearchParams, setFilterSearchParams] =
    useState<FilterSearchParams>(
      withSearchParams ? getSearchParams(search) : {}
    );

  const { newColumns, options, checkedList, setCheckedList } =
    useSavedColumns<TColumn>({
      page: getNormalizePathname(pathname),
      columns: columns || [],
    });

  useEffect(() => {
    if (filterControls && (externalParameters as FilterResetParams)?.IS_RESET) {
      _.unset(externalParameters, 'IS_RESET');

      filterControls.map((control) => {
        _.unset(filterSearchParams, control || '');
      });

      handleUpdateAll(filterSearchParams);
    } else {
      handleUpdateAll({
        ...filterSearchParams,
        ...(externalParameters || {}),
      });
    }
  }, [externalParameters]);

  useEffect(() => {
    if (columns) {
      onChangeColumns?.(newColumns);
    }
  }, [checkedList]);

  useEffect(() => {
    if (withSearchParams) {
      const initialSearchParams = handleInitialSearchParams();

      const rangePickerLeft = _.find(controls.left, {
        type: 'range-picker',
      });

      const rangePickerRight = _.find(controls.right, {
        type: 'range-picker',
      });

      form.setFieldsValue({ ...filterSearchParams, ...initialSearchParams });

      if (rangePickerLeft || rangePickerRight) {
        form.setFieldsValue({
          ...filterSearchParams,
          ...initialSearchParams,
          [String(rangePickerLeft?.formName || rangePickerRight?.formName)]:
            filterSearchParams.dateFrom && filterSearchParams.dateTo
              ? [
                  dayjs(filterSearchParams.dateFrom as Dayjs),
                  dayjs(filterSearchParams.dateTo as Dayjs),
                ]
              : null,
        });
      }

      onChange?.({ ...filterSearchParams, ...initialSearchParams });
      onReady?.(true);
    }
  }, []);

  const handleInitialSearchParams = () => {
    const params: FilterSearchParams = {};

    [...(controls.left || []), ...(controls.right || [])]
      .filter((control) => control.params?.value)
      .map((control) => {
        params[control.formName!] = control.params?.value;
      });

    if (!_.isEmpty(params)) {
      setFilterSearchParams(params);
      handleAfterAction(params);
    }

    return params;
  };

  const navigateTo = (params: FilterSearchParams) =>
    navigate(
      !_.isEmpty(params) ? `${pathname}?${setSearchParams(params)}` : pathname
    );

  const handleAfterAction = (params: FilterSearchParams) =>
    withSearchParams ? navigateTo(params) : onRequest?.();

  const handleCompareParams = (value: unknown, params: FilterSearchParams) =>
    _.keys(value).map((key) => {
      params[key] = (value as keyof typeof value)[key];
    });

  const handleUpdateAll = (params: FilterSearchParams) => {
    onChange?.(params);
    setFilterSearchParams(params);
    handleAfterAction(params);
  };

  const handleChange = (
    value: FilterSearchParams,
    { formName, type, controls }: FilterControl
  ) => {
    if (!value || value?.IS_RESET) {
      if (type === 'range-picker') {
        _.unset(filterSearchParams, 'dateFrom');
        _.unset(filterSearchParams, 'dateTo');
      } else {
        if (formName) {
          _.unset(filterSearchParams, formName);
        }
      }

      if (type === 'module-filter') {
        controls?.map((control) => {
          _.unset(filterSearchParams, control.formName || '');
        });
      }

      handleUpdateAll(filterSearchParams);
    } else {
      const params: FilterSearchParams = {
        ...filterSearchParams,
      };

      if (_.isObject(value)) {
        if (_.has(value, 'reportType')) {
          handleCompareParams(
            {
              ...value,
              reportType: value.reportType ? 'pl' : 'cf',
            },
            params
          );
        } else {
          handleCompareParams(value, params);
        }
      } else {
        if (formName) {
          params[formName] = value;
        }
      }

      handleUpdateAll(params);
    }
  };

  const generateControls = (controls: FilterControl[]) =>
    controls.map((control, index) => (
      <FilterConstructorControls
        key={control.type + index}
        initialParams={filterSearchParams}
        moduleParams={{
          value: checkedList,
          onChange: setCheckedList,
          options,
        }}
        onControlChange={(value) => handleChange(value, control)}
        {...control}
      />
    ));

  return (
    <S.Container>
      <Form form={form} layout="horizontal" requiredMark={false}>
        <Flex align="center" justify="space-between" >
          <Flex align="center" gap={20}>
            {!_.isEmpty(controls.left) && generateControls(controls.left || [])}
          </Flex>

          <Flex align="center" gap={20}>
            <Flex align="center" gap={16}>
              {!_.isEmpty(controls.right) &&
                generateControls(controls.right || [])}
            </Flex>

            {actions}
          </Flex>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default FilterConstructor;
