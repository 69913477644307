import styled from '@emotion/styled';

import { type FormProps, Form, Flex, Typography } from 'antd';

export const Container = styled.div`
  width: 100%;
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 8px;
`;

export const FormControl = styled(Form.Item)<FormProps>`
  && {
    margin-bottom: 0;

    .ui-select-clear {
      margin-top: -8px;
      inset-inline-end: 17px;
    }
  }
`;

export const DrawerContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const DrawerTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

export const DrawerSwitch = styled(Flex)`
  margin-top: 40px;
`;

export const DrawerSwitchLabel = styled.div`
  font-weight: 600;
`;

export const SwitchLabel = styled.div`
  font-weight: 600;
`;
