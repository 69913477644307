import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgGray};
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  border-radius: 12px;
  padding: 20px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.blackSecondary};
  font-weight: 500;
`;

export const Download = styled.a`
  svg {
    margin-top: 4px;
  }
`;

export const Preview = styled.div``;

export const PreviewLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
`;

export const PreviewContent = styled.div`
  margin-top: 16px;

  img {
    width: 100%;
    border-radius: 12px;
  }

  iframe {
    width: 100%;
    height: 500px;
    border-radius: 12px;
    border: 0;
  }
`;
