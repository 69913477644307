import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconArrowLeft } from '@assets';

import * as S from './styled';

const GoBack: FC = () => {
  const navigate = useNavigate();

  const handleBack = (e: MouseEvent) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div>
      <S.Link onClick={handleBack}>
        <IconArrowLeft /> Go Back
      </S.Link>
    </div>
  );
};

export default GoBack;
