import { Params$AdminCompanyFileController_delete } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';
import { Schemas } from '@api-client/generated/types';

const useDeleteCompanyFile = () => {
  return useApiClientMutation<Params$AdminCompanyFileController_delete, Schemas.CompanyFile>({
    method: 'AdminCompanyFileController_delete',
  });
};

export default useDeleteCompanyFile;
