import styled from '@emotion/styled';

import { Typography } from 'antd';

const { Title } = Typography;

export const Heading = styled(Title)`
  && {
    margin-bottom: 0;
  }
`;

export const SubHeading = styled(Title)`
  && {
    &.ui-typography {
      font-size: 24px;
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
`;
