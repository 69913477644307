import { FC, useEffect, useState } from 'react';
import { formatDistance } from 'date-fns';

import { Avatar, Flex, Button, message } from 'antd';
import { ChatWidgetMessageActions } from '@entities';
import { useChat, useAccount } from '@hooks';
import { useUpdateChatComment } from '@hooks-api';
import { IconReply } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

export type ChatWidgetMessageProps = {
  comment: Schemas.Comment;
  hideReply?: boolean;
  isParent: boolean;
  onReply?: () => void;
};

const ChatWidgetMessage: FC<ChatWidgetMessageProps> = ({
  comment,
  isParent,
  hideReply,
  onReply,
}) => {
  const { selectedEditComment, updateComment, selectEdit } = useChat();
  const { companyId } = useAccount();

  const [updatedMessage, setUpdatedMessage] = useState('');

  const [updateChatComment] = useUpdateChatComment();

  useEffect(() => {
    if (selectedEditComment?.id) {
      setUpdatedMessage(selectedEditComment.message);
    }
  }, [selectedEditComment]);

  const handleUpdateMessage = () => {
    if (!updatedMessage.length) {
      return message.open({
        type: 'error',
        content: 'The message must not be empty',
      });
    }

    updateChatComment(
      {
        parameter: {
          id: selectedEditComment.id!,
          companyId: companyId!,
        },
        requestBody: {
          message: updatedMessage,
          severity: 'normal',
        },
      },
      {
        onSuccess: (response) => {
          updateComment(response);

          selectEdit({
            id: null,
            message: '',
          });

          setUpdatedMessage('');
        },
      }
    );
  };

  return (
    <S.Container>
      <S.Header>
        <Avatar size={40}>{comment.user.email!.substring(0, 1)}</Avatar>

        <S.UserName>{comment.user.email}</S.UserName>

        <S.Date>
          {formatDistance(comment.createdAt, new Date(), {
            addSuffix: true,
          })}
        </S.Date>

        <ChatWidgetMessageActions
          commentId={comment.id}
          userId={comment.user.id}
          isParent={isParent}
          onEdit={() => {
            selectEdit({
              id: comment.id,
              message: comment.message,
            });
          }}
        />
      </S.Header>

      <S.Body hideReply={hideReply}>
        {selectedEditComment.id === comment.id ? (
          <Flex gap={8}>
            <S.InputControl
              placeholder="Update comment"
              size="large"
              value={updatedMessage}
              onChange={(e) => setUpdatedMessage(e.target.value)}
            />

            <Button type="primary" onClick={handleUpdateMessage} size="large">
              Save
            </Button>
          </Flex>
        ) : (
          <p>{comment.message}</p>
        )}

        {!hideReply && (
          <S.Reply onClick={onReply}>
            <IconReply /> Reply
          </S.Reply>
        )}
      </S.Body>
    </S.Container>
  );
};

export default ChatWidgetMessage;
