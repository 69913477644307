import styled from '@emotion/styled';

import { Input, type InputProps } from 'antd';

type BodyProps = {
  hideReply?: boolean;
};

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .ui-avatar {
    text-transform: uppercase;
  }
`;

export const UserName = styled.span`
  font-weight: 600;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.gray};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Body = styled.div<BodyProps>`
  width: 100%;
  max-width: 500px;
  padding-left: 52px;

  p {
    margin-bottom: ${({ hideReply }) => (!hideReply ? '8px' : 0)};
  }
`;

export const Reply = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-inline: 0;
  font-weight: 500;
`;

export const InputControl = styled(Input)<InputProps>`
  width: 100%;
  margin-bottom: 8px;
`;
