import { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Typography,
  Flex,
  Breadcrumb,
  Form,
  Input,
  Row,
  Col,
  Segmented,
  message,
} from 'antd';

import { ChatWidget, GoBack } from '@entities';
import { Card, Loader } from '@components';
import { IconArrowRight } from '@assets';
import { useGetCompanyById, useUpdateCompanyById } from '@hooks-api';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

const { Title } = Typography;

const CompanyDetails: FC = () => {
  const { id: companyId } = useParams();

  const [selectedSegment, setSelectedSegment] = useState('Chat');

  const { company, loading } = useGetCompanyById({
    params: {
      id: companyId!,
    },
  });

  const [updateCompany] = useUpdateCompanyById();

  const onFinish = (values: Schemas.AdminCompanyDto) =>
    updateCompany(
      {
        parameter: {
          id: companyId!,
        },
        requestBody: values,
      },
      {
        onSuccess: () => {
          message.open({
            type: 'success',
            content: 'Company information has been successfully updated',
          });
        },
      }
    );

  if (!company || loading) {
    return <Loader />;
  }

  return (
    <Flex gap={24} vertical>
      <Breadcrumb
        separator={<IconArrowRight />}
        items={[
          {
            title: <Link to="/companies">Companies</Link>,
          },
          {
            title: company.name,
          },
        ]}
      />

      <Flex gap={12} vertical>
        <GoBack />
        <Title>{company.name}</Title>
      </Flex>

      <Flex gap={30} vertical>
        <Form
          layout="vertical"
          onFinish={onFinish}
          colon={false}
          requiredMark={false}
          initialValues={company}
        >
          <Row gutter={[48, 30]}>
            <Col span={15}>
              <Card title="Company Details">
                <S.FormDetails>
                  <Row gutter={[30, 0]}>
                    <Col span={12}>
                      <Form.Item name="name" label="Company Name">
                        <Input placeholder="Company Name" size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item name="contactName" label="Contact person">
                        <Input placeholder="Contact person" size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="contactEmail"
                        label="Email"
                        rules={[{ type: 'email' }]}
                      >
                        <Input placeholder="Email" size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item name="contactPhone" label="Phone number">
                        <Input placeholder="Phone number" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                </S.FormDetails>
              </Card>

              <Form.Item noStyle>
                <S.ButtonSubmit type="primary" htmlType="submit" size="large">
                  Save
                </S.ButtonSubmit>
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item name="referenceId" label="Reference ID">
                <Input placeholder="Add reference ID" size="large" disabled />
              </Form.Item>

              <Form.Item name="adminNotes" label="Notes">
                <Input.TextArea
                  placeholder="Add note..."
                  rows={5}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Card>
          <Segmented
            options={['Chat']}
            value={selectedSegment}
            onChange={setSelectedSegment}
            size="large"
          />

          {selectedSegment === 'Chat' && (
            <ChatWidget entityId={companyId!} entityType="company" />
          )}
        </Card>
      </Flex>
    </Flex>
  );
};

export default CompanyDetails;
