import { AxiosError, type AxiosRequestConfig } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';

import apiClient from '@api-client';

type Option = {
  timeout?: AxiosRequestConfig['timeout'];
  signal?: AxiosRequestConfig['signal'];
};

export type QueryOptions<R> = Omit<
  UseQueryOptions<R, AxiosError<unknown, any>, R, QueryKey>,
  'queryKey' | 'queryFn'
>;

type ApiClientProps<P, R> = {
  // Method name as specified in api-client/generated/client.ts
  method: keyof typeof apiClient;
  // A set of parameters required for a specific API request.
  params?: P;
  // Additional options for managing API requests
  option?: Option;
  queryKey?: QueryKey;
  config?: QueryOptions<R>;
};

/**
 * @description Hook for working with the API client and processing methods
 *
 * @example
 *
 * import { Params$AccountsController_getAccounts } from '@api-client/generated/client';
 * import { Schemas } from '@api-client/generated/types';
 * import { useApiClient } from '@hooks';
 *
 * const { data, error, loading } = useApiClient<Params$AccountsController_getAccounts, Schemas.Account[]>({
 *   method: 'AccountsController_getAccounts',
 *   params: {
 *     parameter: {
 *       companyId, // got with hook: const { companyId } = useAccount()
 *     },
 *   },
 *   skip: true | false
 * });
 *
 */

const useApiClient = <P = any, R = null>({
  queryKey,
  method,
  params,
  option,
  config = {},
}: ApiClientProps<P, R>) => {
  const { isLoading, ...rest } = useQuery<R, AxiosError>(
    queryKey || [method, params],
    () =>
      apiClient[method]((params || option) as P | Option | any, option).then(
        (res) => res as R
      ),
    config
  );

  return {
    loading: isLoading,
    ...rest,
  };
};

export default useApiClient;
