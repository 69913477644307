import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { type FlexProps, Flex } from 'antd';

import { type MessagePosition } from './ChatLiveMessage';

import { colors } from '@theme';

type MessageProps = {
  status: string;
  position: MessagePosition;
};

const getParamsByPosition = (position: MessagePosition) => {
  const initialParams = {
    bg: colors.bgGrey,
    radius: '16px 16px 16px 0',
  };

  if (position === 'left') {
    return initialParams;
  }

  if (position === 'right') {
    return {
      bg: colors.bgBrightBlue,
      radius: '16px 16px 0 16px',
    };
  }

  return initialParams;
};

export const Message = styled(Flex)<FlexProps & MessageProps>`
  min-width: 160px;
  max-width: 75%;
  background-color: ${({ theme, position, status }) =>
    status === 'errorsFound'
      ? theme.colors.bgWarning
      : getParamsByPosition(position).bg};
  border-radius: ${({ position }) => getParamsByPosition(position).radius};
  padding: 10px 16px;
  margin-top: 8px;
  position: relative;

  &:hover {
    .chat-message-actions {
      opacity: 1;
    }
  }
`;

export const Text = styled(Flex)<FlexProps>`
  font-weight: 500;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

export const Actions = styled.div<{ position: MessagePosition }>`
  width: 40px;
  height: 20px;
  background: ${({ theme, position }) =>
    position === 'left'
      ? `linear-gradient(to left, ${rgba(theme.colors.bgGrey, 0)}, ${theme.colors.bgGrey}, ${theme.colors.bgGrey})`
      : `linear-gradient(to right, ${rgba(theme.colors.bgBrightBlue, 0)}, ${theme.colors.bgBrightBlue}, ${theme.colors.bgBrightBlue})`};
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 8px;
  opacity: 0;
  transition: 0.1s;
`;

export const DeletedBody = styled.div`
  font-style: italic;
`;
