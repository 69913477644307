import { Params$AdminCompanyController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminCompanyController_updateOneById;

const useUpdateCompanyById = () => {
  const company = useApiClientMutation<Params>({
    method: 'AdminCompanyController_updateOneById',
  });

  return company;
};

export default useUpdateCompanyById;
