import { FC, useRef, useState } from 'react';

import { Avatar, List } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import {
  PopupDelete,
  ChatLiveMessage,
  ChatLiveMessagesListSkeleton,
  ChatLiveUnread,
} from '@entities';
import { Scrollbar } from '@components';

import { useAccount } from '@hooks';

import { getGroupedMessagesById } from '@utils';

import { useTasksStore } from '@store';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type ChatMessage = Schemas.ChatMessage;

type ChatLiveMessagesListProps = {
  selectedId?: string;
  dataSource: ChatMessage[];
  onSeenMessages?: (ids: string[]) => void;
  onRemove?: (id: string) => void;
};

const ChatLiveMessagesList: FC<ChatLiveMessagesListProps> = ({
  selectedId,
  dataSource,
  onSeenMessages,
  onRemove,
}) => {
  const { account } = useAccount();

  const unreadRef = useRef<HTMLDivElement | null>(null);

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);
  const [selectedRemoveId, setSelectedRemoveId] = useState<string | null>(null);

  const { readMessagesOfTask } = useTasksStore();

  const groupedMessages = getGroupedMessagesById(dataSource, account?.id);

  const handleSeenMessages = (isBottom: boolean) => {
    if (isBottom) {
      const unreadMessages = dataSource.filter(
        (message) =>
          !message.seenData
            .map((message) => message.userId)
            .includes(String(account?.id))
      );

      if (unreadMessages.length) {
        const messagesIds = unreadMessages.map((message) => message.id);

        onSeenMessages?.(messagesIds);
        readMessagesOfTask(selectedId!);
      }
    }
  };

  const handleRemoveCancel = () => {
    setIsOpenPopupConfirm(false);
    setSelectedRemoveId(null);
  };

  const handleRemoveMessage = () => {
    setIsOpenPopupConfirm(false);

    if (selectedRemoveId) {
      onRemove?.(selectedRemoveId);
    }
  };

  const getPositionMessage = (id: string) =>
    account?.id !== id ? 'left' : 'right';

  return (
    <Scrollbar
      scrollToRef={unreadRef}
      effectEventListener={selectedId!}
      effectSource={dataSource}
      effectsReload={[dataSource, selectedId!]}
      height="calc(100vh - 400px)"
      onScrollBottom={handleSeenMessages}
      withScrollToBottom
      withoutPadding
    >
      <PopupDelete
        open={isOpenPopupConfirm}
        title="Are you sure you want to delete the message?"
        confirmParams={{
          text: 'Yes, delete',
        }}
        onRemove={handleRemoveMessage}
        onCancel={handleRemoveCancel}
      />

      <S.Container>
        {account && selectedId ? (
          <List
            dataSource={groupedMessages}
            renderItem={({ author, ...group }) => (
              <>
                {!group.unreadMessages && <ChatLiveUnread ref={unreadRef} />}

                <S.Group
                  justify={getPositionMessage(author.id)}
                  align="flex-end"
                  gap={8}
                >
                  {account.id !== author.id && (
                    <Avatar size={40} icon={<UserOutlined />} />
                  )}

                  <S.Inner
                    align={
                      account?.id !== author.id ? 'flex-start' : 'flex-end'
                    }
                    vertical
                  >
                    <S.Header
                      align="center"
                      justify={getPositionMessage(author.id)}
                      gap={4}
                    >
                      <S.Name>{author.fullName}</S.Name>
                    </S.Header>

                    <List
                      dataSource={group.messages}
                      renderItem={(message) => (
                        <ChatLiveMessage
                          position={getPositionMessage(author.id)}
                          onRemove={() => {
                            setIsOpenPopupConfirm(true);
                            setSelectedRemoveId(message.id);
                          }}
                          {...message}
                        />
                      )}
                    />
                  </S.Inner>
                </S.Group>
              </>
            )}
            locale={{
              emptyText: <div />,
            }}
          />
        ) : (
          <ChatLiveMessagesListSkeleton />
        )}
      </S.Container>
    </Scrollbar>
  );
};

export default ChatLiveMessagesList;
