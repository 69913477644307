import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  padding: 0 30px;
`;

export const Skeleton = styled.div<{ position: 'left' | 'right' }>`
  width: 516px;
  height: 67px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: ${({ position }) =>
    position === 'left' ? '16px 16px 16px 0' : '16px 16px 0 16px'};
`;
