import { useMemo } from 'react';

import { Params$AdminIncorporationController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type Params = Params$AdminIncorporationController_findOneById;

type IncorporationProps = {
  params: Params['parameter'];
  config?: QueryOptions<Schemas.Incorporation>;
};

const useGetIncorporationById = ({ config, params }: IncorporationProps) => {
  const {
    data: incorporation,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Incorporation>({
    method: 'AdminIncorporationController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      incorporation,
      loading,
      refetch,
    }),
    [incorporation, loading]
  );
};

export default useGetIncorporationById;
