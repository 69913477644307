import { Params$AdminTransactionController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminTransactionController_updateOneById;

const useUpdateTransactionById = () => {
  const transaction = useApiClientMutation<Params>({
    method: 'AdminTransactionController_updateOneById',
  });

  return transaction;
};

export default useUpdateTransactionById;
