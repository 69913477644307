import styled from '@emotion/styled';

export const Container = styled.div`
  margin-top: 16px;
`;

export const FormReply = styled.div`
  padding-left: 50px;
`;

export const ChildWrapper = styled.div`
  padding-left: 50px;
`;
