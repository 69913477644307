import { FC, useEffect, useState } from 'react';

import { type ModalProps, Modal, Flex } from 'antd';

import { ChatLiveFilesList, SearchControl, FilePreview } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TaskFile = Schemas.TaskFile;

type TaskViewFilesProps = {
  files: TaskFile[];
  onCancel: () => void;
} & ModalProps;

const TaskViewFiles: FC<TaskViewFilesProps> = ({
  open,
  onCancel,
  files,
  ...rest
}) => {
  const [filesList, setFilesList] = useState<TaskFile[]>([]);
  const [selectedFile, setSelectedFile] = useState<TaskFile | null>(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (files.length) {
      setSelectedFile(files[0]);
      setFilesList(files);
    }
  }, [files]);

  const handleSearch = (value: string) => {
    setSearchValue(value);

    setFilesList(
      value.length > 0
        ? filesList.filter((file: TaskFile) =>
            file.fileName.toLowerCase().match(value)
          )
        : files
    );
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setSearchValue('');

    onCancel();
  };

  return (
    <Modal
      width={1242}
      open={open}
      title="Files"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Flex gap={30} align="flex-start">
        <S.Preview>
          <FilePreview title="Preview" file={selectedFile as TaskFile} />
        </S.Preview>

        <S.Files gap={24} vertical>
          <SearchControl
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />

          <ChatLiveFilesList
            onSelect={setSelectedFile}
            dataSource={filesList}
            selectedFile={selectedFile}
            selectedMode
          />
        </S.Files>
      </Flex>
    </Modal>
  );
};

export default TaskViewFiles;
