import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Layout } from 'antd';

import { Header, NotFound, Sidebar } from '@entities';
import { useAccount } from '@hooks';
import { useGetUserData } from '@hooks-api';

import * as S from './styled';
import { accessMapping } from '@constants';
import AccessDenied from '@entities/AccessDenied';

const LayoutProtected: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLogged, account, role, setAccount } = useAccount();
  const { user, loading } = useGetUserData();

  useEffect(() => {
    if (user && !account) {
      setAccount(user);
    }
  }, [user, account]);

  useEffect(() => {
    if (!isLogged) {
      navigate('/login');
    }
  }, [isLogged]);

  if (!user || loading || !role) {
    return null;
  }

  return (
    <S.Container>
      <Sidebar />

      <Layout>
        <Header />

        <S.Content>
          {accessMapping[role as 'kyc' | 'admin' | 'accountant'].some((route) =>
            pathname.includes(route)
          ) ? (
            <Outlet />
          ) : (
            <>
              {Array.from(new Set(Object.values(accessMapping).flat())).some(
                (route) => '/' + route === pathname
              ) ? (
                <AccessDenied />
              ) : (
                <NotFound />
              )}
            </>
          )}
        </S.Content>
      </Layout>
    </S.Container>
  );
};

export default LayoutProtected;
