import { Params$AdminTaskController_close } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCloseTask = () => {
  return useApiClientMutation<Params$AdminTaskController_close, Schemas.Task>({
    method: 'AdminTaskController_close',
  });
};

export default useCloseTask;
