import { useMemo } from 'react';

import { Schemas } from '@api-client/generated/types';

import { QueryOptions, useApiClient } from '@hooks';

type User = Schemas.User;

type GetUsersProps = {
  config?: QueryOptions<User[]>;
};

const useGetUsers = ({ config }: GetUsersProps) => {
  const {
    data: users,
    loading,
    refetch,
  } = useApiClient<any, User[]>({
    method: 'AdminUserController_getUsers',
    config,
  });

  return useMemo(
    () => ({
      users,
      loading,
      refetch,
    }),
    [users, loading]
  );
};

export default useGetUsers;
