import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

import { colors } from '@theme';

type FileProps = {
  selectedFile: boolean;
  selectedMode: boolean;
  status: string | null;
  light: boolean;
};

const getColorBackgroundByParams = (selected: boolean, light: boolean) => {
  if (selected) {
    return colors.bgBrightBlue200;
  }

  if (light) {
    return colors.white;
  }

  return colors.bgGrey;
};

const getColorBorderByParams = (selected: boolean, status: string | null) => {
  if (status === 'errorsFound') {
    return colors.error;
  }

  if (selected) {
    return colors.primary;
  }

  return colors.strokeGrey;
};

export const File = styled(Flex)<FlexProps & FileProps>`
  background-color: ${({ selectedFile, light }) =>
    getColorBackgroundByParams(selectedFile, light)};
  border: 1px solid
    ${({ status, selectedFile }) =>
      getColorBorderByParams(selectedFile, status)};
  border-radius: 12px;
  cursor: pointer;
  padding: ${({ selectedMode }) => (selectedMode ? '24px 20px' : '16px')};
  margin-top: ${({ selectedMode }) => (selectedMode ? '12px' : '4px')};
`;

export const Wrapper = styled(Flex)<FlexProps>`
  width: calc(100% - 70px);
`;

export const Inner = styled(Flex)<FlexProps>`
  width: 100%;
  flex: 1;
`;

export const Name = styled.div<{ selectedMode: FileProps['selectedMode'] }>`
  width: 100%;
  max-width: ${({ selectedMode }) => (selectedMode ? '100%' : '220px')};
  min-width: 150px;
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Size = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;

export const Processing = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
`;
