import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { Layout, type SelectProps, type LayoutProps } from 'antd';

import { Select } from '@components';

export const Header = styled(Layout.Header)<LayoutProps>`
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const UserName = styled.span`
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};
`;

export const CompanyName = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: ${({theme}) => theme.colors.white};
`

export const Logout = styled.a`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 80px;

  &:hover {
    opacity: 0.75;
  }
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: ${({theme}) => theme.colors.textGrey};
`

export const CompanyControl = styled(Select)<SelectProps>`
  && {
    &.ui-select {
      width: 127px;
      height: 40px;

      .ui-select-selector {
        background-color: transparent;
        border-color: ${({ theme }) => rgba(theme.colors.white, 0.4)};
        color: ${({ theme }) => theme.colors.white};

        .ui-select-selection-item {
          color: ${({ theme }) => theme.colors.white};
        }
      }

      .ui-select-arrow {
        color: ${({ theme }) => theme.colors.white};
      }

      &-focused {
        .ui-select-selector {
          border-color: ${({ theme }) => rgba(theme.colors.white, 0.6)};
        }
      }

      &:hover {
        &.ui-select-outlined .ui-select-selector {
          border-color: ${({ theme }) => rgba(theme.colors.white, 0.6)};
        }
      }
    }
  }
`;
