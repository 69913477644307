import {
  Params$AdminUserController_updateCurrentUser,
} from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';
import { Schemas } from '@api-client/generated/types';

type Params = Params$AdminUserController_updateCurrentUser;
type Response = Schemas.User

const useUpdateUserById = () => {
  const user = useApiClientMutation<Params, Response>({
    method: 'AdminUserController_updateCurrentUser',
  });

  return user;
};

export default useUpdateUserById;
