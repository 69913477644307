import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Preview = styled.div`
  width: 497px;
`;

export const Files = styled(Flex)<FlexProps>`
  width: 100%;
  flex: 1;
`;
