import { FC } from 'react';

import { Flex } from 'antd';

import { AccountsList } from '@entities';
import { useGetAccountsList } from '@hooks-api';
import { useAccount } from '@hooks';

import * as S from './styled';

const Accounts: FC = () => {
  const { companyId } = useAccount();

  const { accounts } = useGetAccountsList({
    params: {
      companyId: companyId!,
    },
  });

  return (
    <Flex gap={24} vertical>
      <S.Heading>Accounts</S.Heading>

      <AccountsList accounts={accounts} />
    </Flex>
  );
};

export default Accounts;
