import { useMemo } from 'react';

import {
  Params$AdminTransactionController_toCSV,
} from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

type Params = Params$AdminTransactionController_toCSV;

type TransactionProps = {
  params: Params['parameter'];
  config: any;
};

const useGetTransactionCsv = ({ params, config }: TransactionProps) => {
  const {
    data: csv,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Transaction>({
    method: 'AdminTransactionController_toCSV',
    params: {
      parameter: params,
    },
    config
  });

  return useMemo(
    () => ({
      csv,
      loading,
      refetch,
    }),
    [csv, loading]
  );
};

export default useGetTransactionCsv;
