import { FC, useState, useEffect } from 'react';

import { Flex, Typography, Popover, Radio, Button, Input } from 'antd';

import {
  TransactionsModal,
  DocumentsModal,
  LegalModal,
  ChatLiveFilesList,
  TaskViewFiles,
} from '@entities';
import { Scrollbar } from '@components';

import { IconArrowRight, IconArrowDown } from '@assets';

import { useGetUsers, useUpdateTask } from '@hooks-api';

import { useTasksStore } from '@store';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Company = Schemas.Company;

type TaskDetailsProps = {
  companies: Company[];
  details: Schemas.Task & { files: Schemas.TaskFile[] };
};

const { Text } = Typography;

const getUserNameById = (id: string, users: Schemas.User[]) => {
  const user = users.filter((user) => user.id === id);

  return user?.length ? user[0].fullName : 'Select';
};

const getCompanyNameById = (id: string, companies: Company[]) => {
  const company = companies.filter((company) => company.id === id);

  return company?.length ? company[0].name : null;
};

const TaskDetails: FC<TaskDetailsProps> = ({ details, companies }) => {
  const [users, setUsers] = useState<Schemas.User[]>([]);
  const [selectedAssigneeId, setSelectedAssigneeId] = useState<string | null>(
    null
  );

  const [transactionsModalVisible, setTransactionsModalVisible] =
    useState(false);

  const [documentsModalVisible, setDocumentsModalVisible] = useState(false);

  const [legalModalVisible, setLegalModalVisible] = useState(false);

  const [filesAllVisible, setFilesAllVisible] = useState(false);

  const { updateTask } = useTasksStore();

  const { users: allUsers } = useGetUsers({
    config: {
      onSuccess: (response) => setUsers(response),
    },
  });

  const [updateTaskDetails] = useUpdateTask();

  useEffect(() => {
    if (details?.assigneeId) {
      setSelectedAssigneeId(details?.assigneeId);
    } else {
      setSelectedAssigneeId(null);
    }
  }, [details]);

  const handleSearchUsers = (value: string) => {
    if (value) {
      setUsers(
        (allUsers || []).filter((user) =>
          user.fullName.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setUsers(allUsers || []);
    }
  };

  const handleSaveAssignee = () => {
    if (selectedAssigneeId) {
      updateTaskDetails(
        {
          parameter: {
            id: details.id,
          },
          requestBody: {
            assigneeId: selectedAssigneeId,
          },
        },
        {
          onSuccess: (response) => updateTask(response),
        }
      );
    }
  };

  return (
    <S.Container>
      <Scrollbar height="calc(100vh - 215px)" withoutPadding isHidden>
        <S.Inner>
          <S.Title>Details</S.Title>

          <TransactionsModal
            companyId={details?.companyId}
            open={transactionsModalVisible}
            onCancel={() => setTransactionsModalVisible(false)}
          />

          <DocumentsModal
            companyId={details?.companyId}
            open={documentsModalVisible}
            onCancel={() => setDocumentsModalVisible(false)}
          />

          <LegalModal
            companyId={details?.companyId}
            open={legalModalVisible}
            onCancel={() => setLegalModalVisible(false)}
          />

          <TaskViewFiles
            open={filesAllVisible}
            files={details?.files || []}
            onCancel={() => setFilesAllVisible(false)}
          />

          <S.Details>
            <Flex gap={16} vertical>
              <Flex align="center" justify="space-between">
                <Text type="secondary">Company</Text>
                <Text strong>
                  {getCompanyNameById(details?.companyId, companies)}
                </Text>
              </Flex>

              <Flex align="center" justify="space-between">
                <Text type="secondary">Team</Text>
                <Text>-</Text>
              </Flex>

              {users && (
                <Flex align="center" justify="space-between">
                  <Text type="secondary">Assignee</Text>

                  <Popover
                    placement="bottomRight"
                    content={
                      <S.AssigneeContainer gap={12} vertical>
                        <Input
                          placeholder="Type assignee`s name"
                          size="large"
                          onChange={(e) => handleSearchUsers(e.target.value)}
                        />

                        <Radio.Group
                          value={selectedAssigneeId}
                          onChange={(e) =>
                            setSelectedAssigneeId(e.target.value)
                          }
                        >
                          <S.AssigneeControl vertical>
                            {users.map((user) => (
                              <Radio key={user.id} value={user.id}>
                                {user.fullName}
                              </Radio>
                            ))}
                          </S.AssigneeControl>
                        </Radio.Group>

                        <Button
                          type="primary"
                          size="large"
                          onClick={handleSaveAssignee}
                          disabled={!selectedAssigneeId}
                          block
                        >
                          Save assignee
                        </Button>
                      </S.AssigneeContainer>
                    }
                    arrow={false}
                  >
                    <S.Assignee onClick={(e) => e.preventDefault()}>
                      {getUserNameById(details?.assigneeId, users)}
                      <IconArrowDown />
                    </S.Assignee>
                  </Popover>
                </Flex>
              )}

              <Flex align="center" justify="space-between">
                <Text type="secondary">Status</Text>
                <Text>-</Text>
              </Flex>

              <Flex align="center" justify="space-between">
                <Text type="secondary">Tax payment</Text>
                <Text>-</Text>
              </Flex>
            </Flex>

            <S.Divider />

            <Flex gap={16} vertical>
              <Flex align="center" justify="space-between">
                <Text type="secondary">Last reply</Text>
                <Text>-</Text>
              </Flex>

              <Flex align="center" justify="space-between">
                <Text type="secondary">Time spent for the reply</Text>
                <Text>-</Text>
              </Flex>
            </Flex>

            <S.Divider />

            <Flex gap={16} vertical>
              <S.Link onClick={() => setTransactionsModalVisible(true)}>
                <Text>Transactions</Text>
                <IconArrowRight />
              </S.Link>

              <S.Link onClick={() => setDocumentsModalVisible(true)}>
                <Text>Documents</Text>
                <IconArrowRight />
              </S.Link>

              <S.Link onClick={() => setLegalModalVisible(true)}>
                <Text>Legal</Text>
                <IconArrowRight />
              </S.Link>
            </Flex>

            {details?.files?.length ? (
              <>
                <S.Divider />

                <Flex gap={10} vertical>
                  <Flex align="center" justify="space-between">
                    <Text>Files</Text>

                    <S.ShowAll onClick={() => setFilesAllVisible(true)}>
                      Show all
                    </S.ShowAll>
                  </Flex>

                  <ChatLiveFilesList
                    dataSource={details.files?.slice(0, 3)}
                    light
                  />
                </Flex>
              </>
            ) : null}
          </S.Details>
        </S.Inner>
      </Scrollbar>
    </S.Container>
  );
};

export default TaskDetails;
