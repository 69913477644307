import { FC, useEffect, useState } from 'react';
import { Button, Flex, Form, message, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { Select } from '@components';
import { useAccount } from '@hooks';
import { RangePicker } from '@entities';
import { statusesList, STORAGE_TOKEN_KEY, typesList } from '@constants';
import { downloadZip, getDateDefault, setSearchParams } from '@utils';
import { IconClose } from '@assets';

import * as S from './styled';

type DownloadDocumentsModalProps = {
  page: number;
  open: boolean;
  onCancel: () => void;
  defaultValues?: { type?: string; adminStatus?: string; period?: Dayjs[] };
  onRefreshList?: () => void;
};

const DownloadDocumentsModal: FC<DownloadDocumentsModalProps> = ({
  page,
  open,
  onCancel,
  defaultValues,
  onRefreshList,
}) => {
  const { companyId } = useAccount();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    const params = {
      ...(page && { page }),
      ...(values.type && { type: values.type }),
      ...(values.adminStatus && { adminStatus: values.adminStatus }),
      ...(values.period?.length && {
        dateTo: dayjs(values.period[1]).format('YYYY-MM-DD'),
        dateFrom: dayjs(values.period[0]).format('YYYY-MM-DD'),
      }),
    };

    setIsLoading(true);

    await downloadZip({
      url: `${import.meta.env.VITE_ROUTE_API_BASE_URL}/api/admin/documents/bulk_download?${setSearchParams(params)}`,
      headers: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem(STORAGE_TOKEN_KEY)!}`,
          CompanyId: companyId!,
        },
      },
      fileName: 'documents',
    })
      .then((res: any) => {
        if (res) {
          onRefreshList?.();
          onCancel();
        }
      })
      .catch((err) => {
        message.error('Download files error', err);
      });

    setIsLoading(false);
  };

  useEffect(() => {
    if (defaultValues && Object.keys(defaultValues)?.length) {
      Object.keys(defaultValues)?.forEach((key) =>
        // @ts-expect-error-next-line
        form.setFieldValue(key, defaultValues[key])
      );
    }
  }, [defaultValues]);

  return (
    <Modal
      width={630}
      title={
        <Flex gap={9} vertical>
          <S.Title>Download documents</S.Title>
          <S.Description>
            By clicking the button you’ll download all documents matching
            filters
          </S.Description>
        </Flex>
      }
      open={open}
      onCancel={onCancel}
      footer={null}
      closable={!isLoading}
      centered
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <S.Block gap={20}>
          <Form.Item label="Period" name="period">
            <RangePicker
              key="transaction-date"
              variant="outlined"
              value={[
                form.getFieldValue('dateFrom'),
                form.getFieldValue('dateTo'),
              ]}
              // @ts-expect-error-next-line
              onChange={(date: Date[] | null) => {
                form.setFieldValue(
                  'dateFrom',
                  date === null ? '' : getDateDefault(date[0], 'YYYY-MM-DD')
                );
                form.setFieldValue(
                  'dateTo',
                  date === null ? '' : getDateDefault(date[1], 'YYYY-MM-DD')
                );
              }}
            />
          </Form.Item>
        </S.Block>

        <S.Block gap={20}>
          <Form.Item label="Type" name="type">
            <Select
              variant="outlined"
              placeholder="Select type"
              options={typesList}
              size="large"
              clearIcon={<IconClose width={16} height={16} />}
              onClear={() => form.resetFields(['type'])}
              allowClear
            />
          </Form.Item>

          <Form.Item label="Status" name="adminStatus">
            <Select
              variant="outlined"
              placeholder="Select status"
              options={statusesList}
              size="large"
              clearIcon={<IconClose width={16} height={16} />}
              onClear={() => form.resetFields(['adminStatus'])}
              allowClear
            />
          </Form.Item>
        </S.Block>

        <Flex justify="flex-end">
          <S.Footer>
            <Button size="large" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              Download
            </Button>
          </S.Footer>
        </Flex>
      </Form>
    </Modal>
  );
};

export default DownloadDocumentsModal;
