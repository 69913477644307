import { FC } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';

import { Spin } from 'antd';
import { useAccount } from '@hooks';
import { ErrorFallback } from './ErrorFallback';

function DefaultErrorComponent() {
  const error = useRouteError();
  const stack = error instanceof Error ? error.stack : undefined;

  return <ErrorFallback error={error} componentStack={stack} />;
}

const AppRouter: FC = () => {
  const { routerPaths } = useAccount();

  const router = createBrowserRouter(
    [
      {
        path: '/',
        errorElement: <DefaultErrorComponent />,
        children: routerPaths,
      },
    ],
    {
      basename: import.meta.env.VITE_ROUTE_BASE_URL || '/',
    }
  );
  return <RouterProvider router={router} fallbackElement={<Spin />} />;
};

export default AppRouter;
