import { Params$AdminCommentController_delete } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminCommentController_delete;

const useDeleteChatComment = () => {
  const chat = useApiClientMutation<Params>({
    method: 'AdminCommentController_delete',
  });

  return chat;
};

export default useDeleteChatComment;
