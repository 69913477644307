import styled from '@emotion/styled';

export const Wrapper = styled.div`
  && {
    .ui-select {
      .ui-select-selector {
        flex-wrap: nowrap;
        padding-left: 12px;
      }

      .ui-select-selection-overflow {
        max-width: inherit;
        width: 100px;
        margin-right: 15px;
        flex-wrap: nowrap;
        overflow: hidden;
      }

      .ui-select-selection-overflow-item {
        margin-right: 4px;

        &:after {
          content: ',';
        }
      }

      .ui-select-selection-overflow-item-suffix {
        display: none;
      }
    }
  }
`;
