import { FC } from 'react';

import { Dropdown } from 'antd';

import { useAccount, useChat } from '@hooks';
import { useDeleteChatComment } from '@hooks-api';
import { IconMoreVertical, IconEdit, IconTrash } from '@assets';

import * as S from './styled';

export type ChatWidgetMessageActionsProps = {
  commentId: string;
  userId?: string;
  isParent: boolean;
  onEdit?: () => void;
};

const ChatWidgetMessageActions: FC<ChatWidgetMessageActionsProps> = ({
  isParent,
  commentId,
  userId,
  onEdit,
}) => {
  const { account, companyId } = useAccount();
  const { deleteComment } = useChat();

  const [deleteChatComment] = useDeleteChatComment();

  const handleRemove = () => {
    deleteChatComment(
      {
        parameter: {
          id: commentId,
          companyId: companyId!,
        },
      },
      {
        onSuccess: () => {
          deleteComment(commentId, isParent);
        },
      }
    );
  };

  if (userId !== account?.id) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'edit',
            label: (
              <S.Item onClick={() => onEdit && onEdit()}>
                <IconEdit /> Edit
              </S.Item>
            ),
          },
          {
            key: 'remove',
            label: (
              <S.Item onClick={handleRemove}>
                <IconTrash /> Remove
              </S.Item>
            ),
          },
        ],
      }}
      placement="bottomLeft"
      trigger={['click']}
    >
      <S.Button>
        <IconMoreVertical />
      </S.Button>
    </Dropdown>
  );
};

export default ChatWidgetMessageActions;
