import { useMemo } from 'react';

import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

const useGetUserData = () => {
  const {
    data: user,
    loading,
    refetch,
  } = useApiClient<Schemas.User>({
    method: 'AdminUserController_getCurrentUser',
  });

  return useMemo(
    () => ({
      user,
      loading,
      refetch,
    }),
    [user, loading]
  );
};

export default useGetUserData;
