import { FC } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { List, Flex } from 'antd';

import { TaskItem } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TasksListProps = {
  type: 'open' | 'closed';
  companies: Schemas.Company[];
  dataSource: Schemas.Task[];
};

const TasksList: FC<TasksListProps> = ({ type, companies, dataSource }) => {
  const { id: taskId } = useParams();

  if (_.isEmpty(dataSource)) {
    return null;
  }

  return (
    <Flex gap={12} align="flex-start" vertical>
      <S.Count type={type}>
        {type === 'open' ? 'Open' : 'Closed'}: {dataSource.length}
      </S.Count>

      <List
        dataSource={dataSource}
        renderItem={(item) => (
          <TaskItem companies={companies} selectedId={taskId!} details={item} />
        )}
      />
    </Flex>
  );
};

export default TasksList;
