import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flex } from 'antd';

import { Logotype } from '@entities';
import {
  IconAccounts,
  IconCompanies,
  IconDocuments,
  IconLegalEmpty,
  IconSettings,
  IconTaxes,
  IconTransactions,
  IconTasks,
  IconBilling,
} from '@assets';

import { useAccount } from '@hooks';
import { filteredMenu } from '@utils';

import * as S from './styled';

const Sidebar: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { role } = useAccount();

  const menuList = [
    {
      name: 'Companies',
      to: '/companies',
      icon: <IconCompanies />,
    },
    {
      name: 'Incorporation',
      to: '/incorporation',
      icon: <IconCompanies />,
    },
    {
      name: 'Transactions',
      to: '/transactions',
      icon: <IconTransactions />,
    },
    {
      name: 'Documents',
      to: '/documents',
      icon: <IconDocuments />,
    },
    {
      name: 'Billing',
      to: '/billing',
      icon: <IconBilling />,
    },
    {
      name: 'Accounts',
      to: '/accounts',
      icon: <IconAccounts />,
    },
    {
      name: 'Legal',
      to: '/legal',
      icon: <IconLegalEmpty />,
    },
    {
      name: 'VAT & Taxes',
      to: '/taxes',
      icon: <IconTaxes />,
    },
    {
      name: 'Tasks',
      to: '/tasks',
      icon: <IconTasks />,
    },
  ];

  const menuBottom = [
    {
      to: '/settings',
      name: 'settings',
      icon: <IconSettings />,
    },
  ];

  const filteredMenuList = filteredMenu(menuList, role);

  const filteredMenuBottomList = filteredMenu(menuBottom, role);

  useEffect(() => {
    if (pathname === '/') {
      navigate('/companies');
    }
  }, [pathname]);

  return (
    <S.Sidebar width={105}>
      <Flex vertical>
        <Logotype />

        <S.Menu>
          {filteredMenuList.map((item) => (
            <S.MenuLink key={item.to} to={item.to}>
              {item.icon}
              {item.name}
            </S.MenuLink>
          ))}
        </S.Menu>
      </Flex>

      <S.Menu>
        {filteredMenuBottomList.map((item) => (
          <S.MenuLink key={item.to} to={item.to}>
            {item.icon}
            {item.name}
          </S.MenuLink>
        ))}
      </S.Menu>
    </S.Sidebar>
  );
};

export default Sidebar;
