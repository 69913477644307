import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-block: 12px;
`;

export const UserName = styled.span`
  font-weight: 600;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.gray};

  &:first-letter {
    text-transform: uppercase;
  }
`;
