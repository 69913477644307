import { Params$AdminCompanyFileController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateCompanyFile = () => {
  return useApiClientMutation<
    Params$AdminCompanyFileController_create,
    Schemas.CompanyFile
  >({
    method: 'AdminCompanyFileController_create',
  });
};

export default useCreateCompanyFile;
