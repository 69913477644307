import { Params$AdminBillingPeriodController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type BillingPeriod = Schemas.BillingPeriod;
type Params = Params$AdminBillingPeriodController_findAll;

type BillingPeriodListProps = {
  params: Params['parameter'];
  config?: QueryOptions<BillingPeriod[]>;
};

const useGetBillingPeriodsList = ({
  params,
  config,
}: BillingPeriodListProps) => {
  const {
    data: billingPeriods,
    loading,
    refetch,
  } = useApiClient<Params, BillingPeriod[]>({
    method: 'AdminBillingPeriodController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    billingPeriods: billingPeriods || [],
    loading,
    refetch,
  };
};

export default useGetBillingPeriodsList;
