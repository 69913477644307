import { FC, ReactNode } from 'react';

import { Flex } from 'antd';

import { ChatLiveHeaderSkeleton } from '@entities';

import * as S from './styled';

type ChatLiveHeaderProps = {
  selectedId?: string;
  title: string;
  details?: ReactNode;
  actions?: ReactNode;
  accessType?: 'private' | 'public';
};

const ChatLiveHeader: FC<ChatLiveHeaderProps> = ({
  selectedId,
  title,
  details,
  actions,
}) => (
  <S.Header align="center" justify="space-between" gap={16}>
    {selectedId ? (
      <Flex gap={8} vertical>
        <S.Title>{title}</S.Title>
        {details}
      </Flex>
    ) : (
      <ChatLiveHeaderSkeleton />
    )}

    {actions}
  </S.Header>
);

export default ChatLiveHeader;
