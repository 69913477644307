import styled from '@emotion/styled';

import { Flex, type FlexProps } from 'antd';

type ItemProps = {
  selected: boolean;
};

export const Item = styled(Flex)<FlexProps & ItemProps>`
  height: 80px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.bgDarkGrey : theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  padding: 16px;
  position: relative;
`;

export const Title = styled.div`
  font-weight: 600;
  flex: 1;
`;

export const ServicesCount = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-size: 12px;
`;

export const Amount = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;
