import { Params$AdminTaxController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateTax = () => {
  return useApiClientMutation<Params$AdminTaxController_create, Schemas.Tax>({
    method: 'AdminTaxController_create',
  });
};

export default useCreateTax;
