import styled from '@emotion/styled';

import { Flex, Input, Button } from 'antd';

export const DocumentName = styled.span`
  font-weight: 400;
  color: ${({theme}) => theme.colors.black};
  line-height: 22px;
`;

export const DocumentType = styled.span`
  text-transform: capitalize;
`;

export const ReferenceInput = styled(Input)`
  border: none;
  
  :focus {
    border-radius: 4px;
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.textDisabled};
    background: ${({theme}) => theme.colors.bgGrey};
    outline: none;
  }
`;

export const TableContainer = styled.div`
  && {
    .ui-table-cell {
      height: 46px;
      padding: 0 16px;
    }
    
    && .ui-table-row-selected {
      .ui-table-cell {
        background: ${({theme}) => theme.colors.badgeGrey};
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }
`;

export const DocumentNameWrapper = styled(Flex)`
  height: 100%;
  position: relative;
`

export const Point = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.textBlue400};
  position: absolute;
  left: -10px;
  top: 20px;
`

export const Actions = styled.div`
  width: 192px;
`;

export const PopoverItem = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue200};
  }
`;

export const DownloadButton = styled(Button)`
  padding: 8px;
  background-color: ${({theme}) => theme.colors.bgGrey};
  color: ${({theme}) => theme.colors.blackSecondary};
  opacity: 1;
  
  :hover {
    opacity: 0.7;
  }
`

export const MoreIcon = styled.div`
  color: ${({ theme }) => theme.colors.blackSecondary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const DownloadIcon = styled.div`
  color: ${({ theme }) => theme.colors.blackSecondary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BookedButton = styled(Button)`
  color: ${({theme}) => theme.colors.link};
  
  :disabled {
    border-color: ${({theme}) => theme.colors.textDisabled};
  }
`