import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, Flex, Form, Input, Modal, type ModalProps } from 'antd';

import { Select } from '@components';

import { useCreateTask, useGetUsers } from '@hooks-api';

import { Schemas } from '@api-client/generated/types';

import { useTasksStore } from '@store';

import * as S from './styled';

type TaskCreateProps = {
  companies: Schemas.Company[];
  onCancel: () => void;
} & ModalProps;

const TaskCreate: FC<TaskCreateProps> = ({
  companies,
  open,
  onCancel,
  ...rest
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [form] = Form.useForm();

  const { users, loading: loadingUsers } = useGetUsers({});

  const { addTask } = useTasksStore();

  const [createTask] = useCreateTask();

  const handleSubmit = (values: Schemas.AdminTaskDto) =>
    createTask(
      {
        requestBody: values,
      },
      {
        onSuccess: (task) => {
          navigate(`/tasks/${task.id}${search}`);

          addTask(task);

          form.resetFields();
          onCancel();
        },
      }
    );

  return (
    <Modal
      width={504}
      open={open}
      title="Create a new task"
      footer={null}
      closeIcon={null}
      onCancel={onCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner gap={24} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            isPrivate: false,
          }}
        >
          <Form.Item
            label="Topic"
            name="topic"
            rules={[
              {
                required: true,
                message: 'Select topic from the list',
              },
            ]}
          >
            <Select
              placeholder="Select topic from the list"
              options={[
                {
                  label: 'Taxes',
                  value: 'taxes',
                },
                {
                  label: 'System Error',
                  value: 'system_error',
                },
                {
                  label: 'VAT',
                  value: 'vat',
                },
                {
                  label: 'Payroll',
                  value: 'payroll',
                },
              ]}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'I couldn`t find the report',
              },
            ]}
          >
            <Input placeholder="I couldn`t find the report" size="large" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Type details of your request',
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Type details of your request"
              size="large"
            />
          </Form.Item>

          <Form.Item
            label="Company"
            name="companyId"
            rules={[
              {
                required: true,
                message: 'Select company from the list',
              },
            ]}
          >
            <Select
              placeholder="Select company from the list"
              options={companies.map((company) => ({
                label: company.name,
                value: company.id,
              }))}
              size="large"
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>

          <Form.Item label="Assignee" name="assigneeId">
            <Select
              placeholder="Select member of the support team"
              options={(users || []).map((user) => ({
                label: user.fullName,
                value: user.id,
              }))}
              size="large"
              loading={loadingUsers}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>

          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel} size="large">
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" size="large">
                Send
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default TaskCreate;
