import { Link, type LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';

import { type TagProps, Tag } from 'antd';

export const CompanyLink = styled(Link)<LinkProps>`
  font-weight: 600;
`;

export const SelectedTag = styled(Tag)<TagProps>`
  margin: 0;
`;

export const TableContainer = styled.div`
  max-width: 720px;
`;
