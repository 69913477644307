import styled from '@emotion/styled';

type StatusProps = {
  bgColor: string;
  textColor: string;
};

export const Status = styled.span<StatusProps>`
  min-width: 145px;
  height: 32px;
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.bgGrayLight};
  color: ${({ theme, textColor }) => textColor || theme.colors.black};
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  padding: 0 12px;
`;
