import { FC } from 'react';

import { Flex } from 'antd';

import * as S from './styled';

const ChatLiveMessagesListSkeleton: FC = () => (
  <S.Container gap={24} vertical>
    <Flex justify="flex-end">
      <S.Skeleton position="right" />
    </Flex>

    <S.Skeleton position="left" />

    <Flex justify="flex-end">
      <S.Skeleton position="right" />
    </Flex>
  </S.Container>
);

export default ChatLiveMessagesListSkeleton;
