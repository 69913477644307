import dayjs, { type Dayjs, type UnitTypeLong, type QUnitType } from 'dayjs';

export const DATE_ISO_FORMAT = 'YYYY-MM-DD';

export const getDateRangeByInterval = (
  start: Dayjs | null,
  end: Dayjs | null,
  interval: UnitTypeLong | QUnitType,
  asUnix = false
): (number | Date | Dayjs)[] => {
  const from = dayjs(start);
  const to = dayjs(end);
  const diffInUnits = to.diff(from, interval) + 1;

  return [...Array(diffInUnits).keys()].map((key) =>
    asUnix
      ? from.add(key, interval).valueOf()
      : from.add(key, interval).toDate()
  );
};

export const getDateDefault = (
  date: Date | Dayjs | string | undefined,
  format: string = 'DD.MM.YYYY'
): string => dayjs(date || dayjs()).format(format);

export const getDateFromNow = (date: Date | string) =>
  dayjs(date || dayjs()).fromNow();
