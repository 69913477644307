import { Params$AdminCommentController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminCommentController_updateOneById;

const useUpdateChatComment = () => {
  const chat = useApiClientMutation<Params>({
    method: 'AdminCommentController_updateOneById',
  });

  return chat;
};

export default useUpdateChatComment;
