import { FC, useEffect, useState } from 'react';
import { Flex } from 'antd';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { DefaultOptionType } from 'antd/es/select';

import { Select, type SelectProps } from '@components';
import { IconSearch } from '@assets';
import { colors } from '@theme';

import * as S from './styled';

type CompaniesSelect = SelectProps & {
  label?: string;
  onChange: (value: any) => void;
};

const FilterCategory: FC<CompaniesSelect> = ({
  onChange,
  label,
  value = [],
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [values, setValues] = useState<DefaultOptionType | DefaultOptionType[]>(
    []
  );
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    setValues(value);
  }, []);

  useEffect(() => {
    if (!isDropdownVisible) {
      setSearchValue('');
    }
  }, [isDropdownVisible]);
  const renderValue = (props: CustomTagProps) => {
    const { label } = props;
    return values?.length === 1 ? (
      <S.SingleValue>{label}</S.SingleValue>
    ) : (
      <></>
    );
  };

  const displayedValue =
    values?.length > 1 && !searchValue?.length
      ? `${values?.length} companies`
      : '';

  const filterOption = (input: string, option?: DefaultOptionType) => {
    if (typeof option?.label === 'string') {
      return (option?.label?.toLowerCase() ?? '').includes(input);
    }
    return false;
  };

  const filterSort = (
    firstOption: DefaultOptionType,
    secondOption: DefaultOptionType
  ) => {
    if (
      typeof firstOption?.label === 'string' &&
      typeof secondOption?.label === 'string'
    ) {
      return (firstOption?.label ?? '')
        ?.toLowerCase()
        .localeCompare((secondOption?.label ?? '').toLowerCase());
    }
    return 0;
  };

  const handleRevert = () => {
    onChange?.([]);
    setValues([]);
    setSearchValue('');
    setIsDropdownVisible(false);
  };

  const handleConfirm = () => {
    onChange?.(values.map((item: DefaultOptionType) => item.value));
    setIsDropdownVisible(false);
    setSearchValue('');
  };

  return (
    <S.SelectContainer>
      {label && <S.Label>{label}</S.Label>}
      <Select
        showSearch={false}
        className="ui-category-filter-select"
        filterOption={filterOption}
        filterSort={filterSort}
        searchValue={searchValue}
        mode="multiple"
        size="large"
        value={values}
        tagRender={renderValue}
        onChange={(_, option) => setValues(option)}
        loading={rest.loading}
        options={rest.options}
        open={isDropdownVisible}
        popupClassName="categories-select-dropdown"
        onDropdownVisibleChange={setIsDropdownVisible}
        dropdownRender={(menu) => (
          <S.DropdownRenderWrapper gap={16} vertical>
            <Flex gap={4} vertical>
              <S.SearchInput
                variant="filled"
                placeholder="Search"
                prefix={<IconSearch color={colors.text200} />}
                size="large"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              {menu}
            </Flex>
            <Flex gap={12}>
              <S.DropdownButton
                size="large"
                type="default"
                onClick={handleRevert}
              >
                Revert
              </S.DropdownButton>
              <S.DropdownButton
                size="large"
                type="primary"
                onClick={handleConfirm}
              >
                Confirm
              </S.DropdownButton>
            </Flex>
          </S.DropdownRenderWrapper>
        )}
        optionRender={(oriOption) => (
          <S.Option gap={8}>
            <S.ListCheckbox
              checked={values?.find(
                (value: DefaultOptionType) => value.value === oriOption.value
              )}
            />
            {oriOption.label}
          </S.Option>
        )}
        {...rest}
      />
      <S.DisplayedValue>{displayedValue}</S.DisplayedValue>
    </S.SelectContainer>
  );
};

export default FilterCategory;
