import { Params$AdminCommentController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminCommentController_create;

const useCreateChatComment = () => {
  const chat = useApiClientMutation<Params, Schemas.Comment>({
    method: 'AdminCommentController_create',
  });

  return chat;
};

export default useCreateChatComment;
