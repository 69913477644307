import { FC, createContext, useState, type PropsWithChildren } from 'react';

import { Schemas } from '@api-client/generated/types';

type Comment = Schemas.Comment;

type SelectedComment = {
  id: string | null;
  message: string;
};

type ChatContextProps = {
  chatComments: Comment[];
  selectedEditComment: SelectedComment;
  initialComments: (comments: Comment[]) => void;
  createComment: (newComment: Comment, parentId: string) => void;
  updateComment: (
    updatedComment: (Comment & { parentId: string }) | null
  ) => void;
  deleteComment: (id: string, isParent: boolean) => void;
  selectEdit: (comment: SelectedComment) => void;
};

export const ChatContext = createContext<ChatContextProps>(
  {} as ChatContextProps
);

export const ChatProvider: FC<PropsWithChildren> = ({ children }) => {
  const [comments, setComments] = useState<Comment[]>([]);

  const [selectedEditComment, setSelectedEditComment] =
    useState<SelectedComment>({
      id: null,
      message: '',
    });

  const initialComments = (comments: Comment[]) => setComments(comments);

  const createComment = (newComment: Comment, parentId: string) => {
    setComments((prevState) =>
      parentId
        ? prevState.map((comment) => {
            if (comment.id === parentId) {
              return {
                ...comment,
                childComments: [...(comment.childComments || []), newComment],
              };
            }

            return comment;
          })
        : [newComment, ...prevState]
    );
  };

  const updateComment = (
    updatedComment: (Comment & { parentId: string }) | null
  ) => {
    if (updatedComment) {
      setComments((prevState) =>
        prevState.map((comment) => {
          if (!updatedComment.parentId && comment.id === updatedComment.id) {
            return {
              ...comment,
              ...updatedComment,
            };
          }

          if (comment.id === updatedComment.parentId) {
            return {
              ...comment,
              childComments: (comment.childComments || []).map((child) => {
                if (child.id === updatedComment.id) {
                  return {
                    ...child,
                    message: updatedComment.message,
                  };
                }

                return child;
              }),
            };
          }

          return comment;
        })
      );
    }
  };

  const deleteComment = (id: string, isParent: boolean) => {
    if (isParent) {
      const filteredComments = comments.filter((comment) => comment.id !== id);

      setComments(filteredComments);
    } else {
      const filteredComments = comments.map((comment) => ({
        ...comment,
        childComments: comment.childComments?.filter(
          (child) => child.id !== id
        ),
      }));

      setComments(filteredComments);
    }
  };

  const selectEdit = (comment: SelectedComment) =>
    setSelectedEditComment(comment);

  return (
    <ChatContext.Provider
      value={{
        chatComments: comments,
        selectedEditComment,
        initialComments,
        createComment,
        updateComment,
        deleteComment,
        selectEdit,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
