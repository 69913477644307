import { Params$AdminBillingPeriodController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type Params = Params$AdminBillingPeriodController_findOneById;
type BillingPeriod = Schemas.BillingPeriod;

type BillingPeriodProps = {
  params: Params['parameter'];
  config?: QueryOptions<BillingPeriod>;
};

const useGetBillingPeriodById = ({ params, config }: BillingPeriodProps) => {
  const { data: billingPeriod, ...rest } = useApiClient<Params, BillingPeriod>({
    method: 'AdminBillingPeriodController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    billingPeriod: billingPeriod || null,
    ...rest,
  };
};

export default useGetBillingPeriodById;
