import { FC, useState } from 'react';

import { Avatar, Button, message as messageRoot } from 'antd';

import { useCreateChatComment } from '@hooks-api';
import { useAccount, useChat } from '@hooks';

import { Parameter$AdminCommentController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Parameters = Parameter$AdminCommentController_findAll;

export type ChatWidgetFormProps = {
  avatar: string;
  entityId: string;
  entityType: Parameters['entityType'];
  parentId?: string | null;
  severity: Schemas.AdminCreateCommentDto['severity'];
};

const ChatWidgetForm: FC<ChatWidgetFormProps> = ({
  parentId,
  entityId,
  entityType,
  severity,
  avatar,
}) => {
  const { account, companyId } = useAccount();
  const { createComment } = useChat();

  const [message, setMessage] = useState('');

  const [sendMessage] = useCreateChatComment();

  const parentIdParams = parentId
    ? {
        parentComment: {
          id: parentId,
        },
      }
    : {};

  const handleCreateComment = (newComment: Schemas.Comment) =>
    createComment(
      {
        ...newComment,
        user: {
          ...newComment.user,
          email: account?.email as string,
        },
      },
      parentId as string
    );

  const handleSendMessage = () => {
    if (!message.length) {
      return messageRoot.open({
        type: 'error',
        content: 'The message must not be empty',
      });
    }

    sendMessage(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          message,
          severity,
          entityType,
          entityId,
          ...parentIdParams,
        },
      },
      {
        onSuccess: (response) => {
          setMessage('');
          handleCreateComment(response);
        },
      }
    );
  };

  return (
    <S.Wrapper isParent={!!parentId}>
      <Avatar size={40}>{avatar!.substring(0, 1)}</Avatar>

      <S.Field
        value={message}
        placeholder="Add a comment"
        onChange={(e) => setMessage(e.target.value)}
        size="large"
      />

      <Button type="primary" onClick={handleSendMessage} size="large">
        Send
      </Button>
    </S.Wrapper>
  );
};

export default ChatWidgetForm;
