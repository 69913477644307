import { Params$AdminDocumentController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminDocumentController_updateOneById;

const useUpdateDocumentById = () => {
  const file = useApiClientMutation<Params>({
    method: 'AdminDocumentController_updateOneById',
  });

  return file;
};

export default useUpdateDocumentById;
