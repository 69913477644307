import { Params$AdminTransactionController_findAll } from '@api-client/generated/client';

import { useApiClient } from '@hooks';

type Params = Params$AdminTransactionController_findAll;

type TransactionsListProps = {
  params: Params['parameter'];
  config: any;
};

const useGetTransactionsList = ({ params, config }: TransactionsListProps) => {
  const {
    data: transactions,
    loading,
    refetch,
  } = useApiClient<Params, string>({
    method: 'AdminTransactionController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    transactions: transactions || [],
    loading,
    refetch,
  };
};

export default useGetTransactionsList;
