import styled from '@emotion/styled';

export const Panel = styled.div`
  height: 28px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 24px 0 16px 0;
`;
