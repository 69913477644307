import { FC, useEffect, useState } from 'react';

import { Flex, Button, Spin } from 'antd';

import {
  ChatLiveCard,
  ChatLiveHeader,
  ChatLiveMessagesList,
  ChatLiveForm,
  TaskDetails,
  TasksUploadFiles,
} from '@entities';

import { useSocketChat } from '@hooks-socket';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TasksChatProps = {
  companies: Schemas.Company[];
  taskId: string;
  type: 'open' | 'closed';
  details: Schemas.Task & { files: Schemas.TaskFile[] };
  onResolve?: () => void;
};

const getTopicNameByValue = (topic: string) => {
  switch (topic) {
    case 'taxes':
      return 'Taxes';

    case 'system_error':
      return 'System Error';

    case 'vat':
      return 'VAT';

    case 'payroll':
      return 'Payroll';

    default:
      return '';
  }
};

const TasksChat: FC<TasksChatProps> = ({
  companies,
  taskId,
  type,
  details,
  onResolve,
}) => {
  const [modalAttachVisible, setModalAttachVisible] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [disabledForm, setDisabledForm] = useState(true);

  const {
    messages,
    isLoading,
    createMessage,
    deleteMessage,
    markAsSeenMessage,
  } = useSocketChat({
    taskId: taskId!,
    companyId: details?.companyId,
  });

  useEffect(() => {
    if (newMessage) {
      setNewMessage('');
      setDisabledForm(true);
    }
  }, [taskId]);

  const handleCreateMessage = () => {
    if (newMessage) {
      createMessage(newMessage);
      setNewMessage('');
    }

    setDisabledForm(true);
  };

  const handleChangeMessage = (message: string) => {
    setNewMessage(message);
    setDisabledForm(!message);
  };

  return (
    <ChatLiveCard
      details={
        taskId ? <TaskDetails companies={companies} details={details} /> : null
      }
    >
      <TasksUploadFiles
        actionCreateMessage={createMessage}
        selectedId={taskId}
        companyId={details?.companyId}
        open={modalAttachVisible}
        onCancel={() => setModalAttachVisible(false)}
      />

      <ChatLiveHeader
        selectedId={taskId}
        title={details?.title}
        details={
          <Flex align="center" gap={16}>
            <S.Count type={type}>{type === 'open' ? 'Open' : 'Closed'}</S.Count>

            <S.Divider />

            <S.CompanyName>{getTopicNameByValue(details?.topic)}</S.CompanyName>
          </Flex>
        }
        actions={
          type === 'open' && taskId ? (
            <Button type="success" size="large" onClick={onResolve}>
              Resolve
            </Button>
          ) : null
        }
      />

      <Spin spinning={isLoading}>
        <ChatLiveMessagesList
          selectedId={taskId}
          dataSource={messages}
          onSeenMessages={markAsSeenMessage}
          onRemove={deleteMessage}
        />
      </Spin>

      <S.Inner>
        <ChatLiveForm
          selectedId={taskId}
          fieldParams={{
            placeholder: 'Type your question',
            value: newMessage,
            onPressEnter: handleCreateMessage,
          }}
          buttonParams={{
            disabled: disabledForm || !taskId,
          }}
          onAttach={() => setModalAttachVisible(true)}
          onSend={handleCreateMessage}
          onChange={handleChangeMessage}
        />
      </S.Inner>
    </ChatLiveCard>
  );
};

export default TasksChat;
