import { useMemo } from 'react';

import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';
import { Params$AdminAccountsController_getAccounts } from '@api-client/generated/client';

type Params = Params$AdminAccountsController_getAccounts;

type AccountsProps = {
  params: Params['parameter'];
};

const useGetAccountsList = ({ params }: AccountsProps) => {
  const {
    data: accounts,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Account[]>({
    method: 'AdminAccountsController_getAccounts',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      accounts: accounts || [],
      loading,
      refetch,
    }),
    [accounts, loading]
  );
};

export default useGetAccountsList;
