import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Flex, Tabs, Switch, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Select } from '@components';
import { SearchControl, TaxesList } from '@entities';
import { getDateRangeByInterval } from '@utils';

import { useGetTaxesList } from '@hooks-api';
import { useAccount } from '@hooks';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Tax = Schemas.Tax;

const months: Record<string, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

const Taxes: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useAccount();

  const [taxesList, setTaxesList] = useState<Tax[]>([]);

  const { taxes } = useGetTaxesList({
    params: {
      companyId: companyId!,
    },
  });

  useEffect(() => {
    if (taxes) {
      setTaxesList(taxes);
    }
  }, [taxes]);

  const handleSearch = (value: string) => {
    setTaxesList(
      value.length > 0
        ? taxes.filter((tax: Tax) =>
            tax.details.reference.toLowerCase().match(value)
          )
        : taxes
    );
  };

  const handleFilterByUnpaid = (isUnpaid: boolean) => {
    setTaxesList(isUnpaid ? taxes.filter((tax: Tax) => !tax.paidAt) : taxes);
  };

  const handleFilterByType = (type: string) => {
    setTaxesList(
      type !== 'all' ? taxes.filter((tax: Tax) => tax.type === type) : taxes
    );
  };

  const getTabsContent = () => {
    const years = getDateRangeByInterval(
      dayjs().subtract(3, 'year'),
      dayjs(),
      'year'
    );

    return years.map((year) => ({
      key: dayjs(year).format('YYYY'),
      label: dayjs(year).format('YYYY'),
    }));
  };

  const getTaxesByPeriod = (period: string) => {
    const currentYear = dayjs().format('YYYY');

    return taxesList.filter((tax) => {
      if (tax.periodType === 'quarter') {
        if (tax.period === period) {
          return tax;
        }
      }

      if (tax.periodType === 'month') {
        const quarterByMonth = dayjs().month(months[tax.period]).quarter();

        if (`Q${quarterByMonth} ${currentYear}` === period) {
          return tax;
        }
      }

      if (tax.periodType === 'year') {
        if (`total_${tax.period}` === period) {
          return tax;
        }
      }
    });
  };

  return (
    <Flex gap={30} vertical>
      <S.Title>VAT & Taxes</S.Title>

      <Flex align="center" justify="space-between">
        <Flex align="center" gap={24}>
          <SearchControl onChange={(e) => handleSearch(e.target.value)} />

          <S.SwitchControl>
            <Switch onChange={handleFilterByUnpaid} /> Show unpaid taxes
          </S.SwitchControl>
        </Flex>

        <Flex align="center" gap={16}>
          <Select
            width={188}
            placeholder="By type of tax"
            options={[
              {
                label: 'All',
                value: 'all',
              },
              {
                label: 'VAT',
                value: 'vat',
              },
              {
                label: 'Corporate',
                value: 'corporate',
              },
              {
                label: 'Social',
                value: 'social',
              },
            ]}
            size="large"
            onChange={handleFilterByType}
          />

          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => navigate('/taxes/create')}
          >
            Add tax
          </Button>
        </Flex>
      </Flex>

      <Tabs
        defaultActiveKey={dayjs().format('YYYY')}
        size="large"
        items={getTabsContent().map((tab) => ({
          key: tab.key,
          label: tab.label,
          children: (
            <Flex gap={16} vertical>
              <TaxesList
                label={`Q1 ${tab.label}`}
                taxes={getTaxesByPeriod(`Q1 ${tab.key}`)}
                period={`Q1 ${tab.key}`}
              />

              <TaxesList
                label={`Q2 ${tab.label}`}
                taxes={getTaxesByPeriod(`Q2 ${tab.key}`)}
                period={`Q2 ${tab.key}`}
              />

              <TaxesList
                label={`Q3 ${tab.label}`}
                taxes={getTaxesByPeriod(`Q3 ${tab.key}`)}
                period={`Q3 ${tab.key}`}
              />

              <TaxesList
                label={`Q4 ${tab.label}`}
                taxes={getTaxesByPeriod(`Q4 ${tab.key}`)}
                period={`Q4 ${tab.key}`}
              />

              <TaxesList
                label={`Total annual ${tab.label}`}
                taxes={getTaxesByPeriod(`total_${tab.key}`)}
                period="year"
                year={tab.key}
              />
            </Flex>
          ),
        }))}
      />
    </Flex>
  );
};

export default Taxes;
