import { Params$AdminDocumentController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { QueryOptions, useApiClient } from '@hooks';

type Params = Params$AdminDocumentController_findAll;
type PaginatedDocumentResponse = Schemas.PaginatedDocumentResponse;

type DocumentsListProps = {
  params: Params['parameter'];
  config?: QueryOptions<PaginatedDocumentResponse>;
};

const useGetDocumentsList = ({ params, config }: DocumentsListProps) => {
  const {
    data: files,
    loading,
    refetch,
    ...rest
  } = useApiClient<Params, PaginatedDocumentResponse>({
    method: 'AdminDocumentController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    files: files || {
      records: [],
      metadata: {} as PaginatedDocumentResponse['metadata'],
    },
    loading,
    refetch,
    ...rest,
  };
};

export default useGetDocumentsList;
