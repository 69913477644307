import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Flex, Button, Spin } from 'antd';

import {
  PopupDelete,
  BillingServicesList,
  BillingDetailsSkeleton,
  BillingServicesEmpty,
} from '@entities';

import { Scrollbar } from '@components';

import { useAccount } from '@hooks';
import { useRemoveBillingPeriod } from '@hooks-api';

import { IconEditUnderline, IconPlus, IconTrash } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type BillingDetailsProps = {
  details: Schemas.BillingPeriod | null;
  isLoading: boolean;
  onAdd: () => void;
  onEdit: () => void;
  onEditService: (details: Schemas.InvoiceItem) => void;
  onAfterRemove: (id: string) => void;
};

const BillingDetails: FC<BillingDetailsProps> = ({
  details,
  isLoading,
  onAdd,
  onEdit,
  onEditService,
  onAfterRemove,
}) => {
  const navigate = useNavigate();

  const { companyId } = useAccount();

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const [removeBilling] = useRemoveBillingPeriod();

  const handleRemoveCancel = (id: string) => {
    removeBilling(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
      },
      {
        onSuccess: () => {
          setIsOpenPopupConfirm(false);
          onAfterRemove?.(id);

          navigate('/billing');
        },
      }
    );
  };

  const getDatePeriod = (period: string) => dayjs(period).format('DD.MM.YYYY');

  if (isLoading) {
    return (
      <S.Container justify="center" align="center">
        <Spin />
      </S.Container>
    );
  }

  if (!details) {
    return (
      <S.Container>
        <BillingDetailsSkeleton />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <PopupDelete
        open={isOpenPopupConfirm}
        title="Are you sure you want to delete the billing?"
        confirmParams={{
          text: 'Yes, delete',
        }}
        onRemove={() => handleRemoveCancel(details.id)}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      <Scrollbar height="calc(100vh - 215px)" withoutPadding isHidden>
        <S.Header align="flex-start" justify="space-between" gap={16}>
          <Flex vertical>
            <S.Title align="center" gap={8}>
              {getDatePeriod(details.startDate)} -{' '}
              {getDatePeriod(details.endDate)}
              <S.ButtonEdit
                type="link"
                icon={<IconEditUnderline />}
                size="large"
                onClick={onEdit}
              />
            </S.Title>

            <S.Details>{details.items.length} services</S.Details>
          </Flex>

          <Flex gap={8}>
            <Button
              type="default"
              size="large"
              icon={<IconPlus />}
              onClick={onAdd}
              ghost
            >
              Add service
            </Button>

            <Button
              type="primary"
              size="large"
              icon={<IconTrash />}
              onClick={() => setIsOpenPopupConfirm(true)}
              danger
            />
          </Flex>
        </S.Header>

        {!details.items.length ? (
          <BillingServicesEmpty onAdd={onAdd} />
        ) : (
          <BillingServicesList
            dataSource={details.items}
            onEdit={onEditService}
          />
        )}
      </Scrollbar>
    </S.Container>
  );
};

export default BillingDetails;
