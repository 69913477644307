import { TableColumnType } from 'antd';
import { useEffect, useState } from 'react';

const useSavedColumns = <TColumn, TPage = any>({
  page,
  columns,
}: {
  page: TPage | string;
  columns: TableColumnType<TColumn>[];
}) => {
  const pageColumns = localStorage.getItem(page as string);

  const defaultCheckedList = pageColumns
    ? JSON.parse(pageColumns)
    : columns.reduce((acc: string[], { title, key }) => {
        if (title !== '') {
          acc.push(key as string);
        }
        return acc;
      }, []);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  useEffect(() => {
    localStorage.setItem(page as string, JSON.stringify(checkedList));
  }, [checkedList]);

  const newColumns = columns.map((item) => ({
    ...item,
    hidden:
      item.key === 'file' ? false : !checkedList.includes(item.key as string),
  }));

  const options = columns.reduce<{ label: string; value: string }[]>(
    (acc, { key, title }) => {
      if (title !== '') {
        acc.push({
          label: title as string,
          value: key as string,
        });
      }
      return acc;
    },
    []
  );

  return { newColumns, options, checkedList, setCheckedList };
};

export default useSavedColumns;
