import { useApiClientMutation } from '@hooks';

import { Params$AdminAccountsController_syncConnection } from '@api-client/generated/client';

type Params = Params$AdminAccountsController_syncConnection;

const useSyncAccount = () => {
  const res = useApiClientMutation<Params>({
    method: 'AdminAccountsController_syncConnection',
  });

  return res;
};

export default useSyncAccount;
