import { FC, ReactNode } from 'react';

import { GoBack } from '@entities';

import { Space } from 'antd';

import * as S from './styled';

type DetailsHeaderProps = {
  children?: ReactNode;
};

const DetailsHeader: FC<DetailsHeaderProps> = ({ children }) => (
  <S.Header>
    <Space split={<S.Divider />}>
      <GoBack />
      {children}
    </Space>
  </S.Header>
);

export default DetailsHeader;
