import { Params$AdminTaskController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type Task = Schemas.Task;
type Params = Params$AdminTaskController_findAll;

type TasksListProps = {
  params: Params['parameter'];
  config?: QueryOptions<Task[]>;
};

const useGetTasksList = ({ params, config }: TasksListProps) => {
  const {
    data: tasks,
    loading,
    refetch,
  } = useApiClient<Params, Task[]>({
    method: 'AdminTaskController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    tasks: tasks || [],
    loading,
    refetch,
  };
};

export default useGetTasksList;
