import { FC, ReactNode, PropsWithChildren } from 'react';

import * as S from './styled';

type ChatLiveCardProps = {
  details?: ReactNode;
};

const ChatLiveCard: FC<PropsWithChildren<ChatLiveCardProps>> = ({
  children,
  details,
}) => (
  <S.Container>
    <S.Inner justify="space-between" vertical>
      {children}
    </S.Inner>

    {details}
  </S.Container>
);

export default ChatLiveCard;
