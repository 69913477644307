import { FC } from 'react';

import { Flex } from 'antd';

import { ChatLiveMessageActions, ChatLiveFilesList } from '@entities';

import { getDateFromNow } from '@utils';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

export type MessagePosition = 'left' | 'right';

type ChatMessage = Schemas.ChatMessage;

type ChatLiveMessageProps = {
  position?: MessagePosition;
  status?: string;
  files?: Schemas.TaskFile[] | null;
  onRemove?: () => void;
} & ChatMessage;

const ChatLiveMessage: FC<ChatLiveMessageProps> = ({
  body,
  createdAt,
  deletedAt,
  status,
  files,
  position = 'left',
  onRemove,
}) => (
  <Flex justify={position === 'left' ? 'flex-start' : 'flex-end'}>
    <S.Message status={status!} position={position} gap={6} vertical>
      <S.Text align="flex-start" justify="space-between" gap={8}>
        {deletedAt ? (
          <S.DeletedBody>The message was deleted</S.DeletedBody>
        ) : (
          body
        )}

        {!deletedAt && (
          <S.Actions position={position} className="chat-message-actions">
            <ChatLiveMessageActions onRemove={onRemove} />
          </S.Actions>
        )}
      </S.Text>

      {!deletedAt && files && <ChatLiveFilesList dataSource={files} />}

      {!deletedAt && createdAt && status !== 'errorsFound' && (
        <S.Date>{getDateFromNow(createdAt)}</S.Date>
      )}
    </S.Message>
  </Flex>
);

export default ChatLiveMessage;
