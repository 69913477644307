import { FC } from 'react';

import { Flex } from 'antd';

import * as S from './styled';

const ChatLiveHeaderSkeleton: FC = () => (
  <Flex gap={8} vertical>
    <S.Skeleton width={460} />
    <S.Skeleton width={160} />
  </Flex>
);

export default ChatLiveHeaderSkeleton;
