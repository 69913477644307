import { Params$AdminAuthController_login } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminAuthController_login;

const useAuthLogIn = () => {
  const auth = useApiClientMutation<Params, Schemas.AdminUserJwtResponseDto>({
    method: 'AdminAuthController_login',
  });

  return auth;
};

export default useAuthLogIn;
