import { FC, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { type TableColumnType, Table, Typography, Flex, Button } from 'antd';

import { SearchControl } from '@entities';
import { useGetCompaniesList } from '@hooks-api';
import { useAccount } from '@hooks';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

const { Title } = Typography;

const Companies: FC = () => {
  const [filterSearch, setFilterSearch] = useState(null);

  const { companyId, setCompany } = useAccount();

  const { companies, loading, refetch } = useGetCompaniesList({
    params: {
      page: 1,
      term: filterSearch!,
    },
  });

  useEffect(() => {
    if (filterSearch || filterSearch === '') {
      refetch();
    }
  }, [filterSearch]);

  const columns: TableColumnType<Schemas.Company>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Company Name',
      width: 160,
      render: (name, record) => (
        <S.CompanyLink to={record.id}>{name}</S.CompanyLink>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: 160,
    },
    {
      key: 'actions',
      align: 'right',
      render: (_, record) =>
        companyId === record.id ? (
          <S.SelectedTag color="success" bordered={false}>
            Selected
          </S.SelectedTag>
        ) : (
          <Button
            type="primary"
            size="large"
            onClick={() => setCompany(record)}
          >
            Select
          </Button>
        ),
    },
  ];

  const handleSearch = useDebouncedCallback((value) => {
    setFilterSearch(value);
  }, 1000);

  // for test purposes, will be removed once sentry is set
  if ((window as any).throwError) {
    ('foo' as any as () => void)();
  }

  return (
    <Flex gap={24} vertical>
      <Title>Companies</Title>

      <SearchControl onChange={(e) => handleSearch(e.target.value)} />

      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          dataSource={companies}
          columns={columns}
          loading={loading}
          pagination={false}
          scroll={{ x: 720 }}
        />
      </S.TableContainer>
    </Flex>
  );
};

export default Companies;
