import React from 'react';
import { createRoot } from 'react-dom/client';
import { init } from '@sentry/react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import App from './App';

import 'antd/dist/reset.css';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

if (import.meta.env.MODE !== 'development') {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    maxBreadcrumbs: 50,
    environment: import.meta.env.MODE,
  });
}

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
