import { FC } from 'react';

import * as S from './styled';

type EmptyDocumentPreviewProps = {
  unsupported?: boolean;
};

const EmptyDocumentPreview: FC<EmptyDocumentPreviewProps> = ({
  unsupported = false,
}) => {
  return (
    <S.Container justify="center" align="center" vertical gap={15} flex={1}>
      <S.IconContainer>
        <S.Icon />
      </S.IconContainer>
      <S.Title level={4}>
        No preview to display
      </S.Title>
      <S.Description>
        {unsupported
          ? 'File type is not supported to display preview'
          : 'Upload the document to display preview here'}
      </S.Description>
    </S.Container>
  );
};

export default EmptyDocumentPreview;
