import styled from '@emotion/styled';

type CountProps = {
  type: 'open' | 'closed';
};

export const Count = styled.div<CountProps>`
  background-color: ${({ theme, type }) =>
    type === 'open' ? theme.colors.badgeSuccess : theme.colors.badgeGrey};
  border-radius: 8px;
  color: ${({ theme, type }) =>
    type === 'open' ? theme.colors.success : theme.colors.text400};
  font-weight: 600;
  padding: 6px 8px;
`;
