import { useMemo } from 'react';

import { Params$AdminTransactionController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

type Params = Params$AdminTransactionController_findOneById;

type TransactionProps = {
  params: Params['parameter'];
};

const useGetTransactionById = ({ params }: TransactionProps) => {
  const {
    data: transaction,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Transaction>({
    method: 'AdminTransactionController_findOneById',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      transaction,
      loading,
      refetch,
    }),
    [transaction, loading]
  );
};

export default useGetTransactionById;
