import { Params$AdminDocumentController_findOneById } from '@api-client/generated/client';

import { useMemo } from 'react';
import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

type Params = Params$AdminDocumentController_findOneById;

type DocumentProps = {
  params: Params['parameter'];
};

const useGetDocumentById = ({ params }: DocumentProps) => {
  const {
    data: file,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Document>({
    method: 'AdminDocumentController_findOneById',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      file,
      loading,
      refetch,
    }),
    [file, loading]
  );
};

export default useGetDocumentById;
