import styled from '@emotion/styled';

type CountProps = {
  type: 'open' | 'closed';
};

export const CompanyName = styled.div`
  width: 160px;
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
  font-weight: 600;
`;

export const Count = styled.div<CountProps>`
  background-color: ${({ theme, type }) =>
    type === 'open' ? theme.colors.badgeSuccess : theme.colors.badgeGrey};
  border-radius: 8px;
  color: ${({ theme, type }) =>
    type === 'open' ? theme.colors.success : theme.colors.text400};
  font-weight: 600;
  font-size: 12px;
  padding: 4px 8px;
`;

export const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
`;

export const Inner = styled.div`
  padding: 30px;
`;
