import styled from '@emotion/styled';

export const Header = styled.div`
  position: sticky;
  top: 70px;
  z-index: 3;
  background: ${({ theme }) => theme.colors.bgLightBlue200};
  margin: -50px -50px 0px -50px;
  padding: 25px 50px 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
`;

export const Divider = styled.div`
  height: 35px;
  border-right: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 10px 0px 5px;
`;
