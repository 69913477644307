import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col, Flex } from 'antd';

import { DetailsHeader, TaxDetails, TaxPreview } from '@entities';
import { CopyButton } from '@components';

import { useGetTaxById } from '@hooks-api';
import { useAccount } from '@hooks';

type TaxesDetailsProps = {
  type: 'create' | 'view';
};

const TaxesDetails: FC<TaxesDetailsProps> = ({ type }) => {
  const { companyId } = useAccount();
  const { id: taxId } = useParams();

  const { tax } = useGetTaxById({
    params: {
      id: taxId!,
      companyId: companyId!,
    },
    config: {
      enabled: type === 'view',
    },
  });

  return (
    <Flex gap={32} vertical>
      <DetailsHeader>
        {type === 'view' && (
          <CopyButton
            tooltipPlacement="bottom"
            icon="chain"
            value={location.href}
            type="link"
          />
        )}
      </DetailsHeader>

      <Row gutter={[30, 0]}>
        <Col span={14}>
          <Flex gap={20} vertical>
            <TaxDetails
              // @ts-expect-error-next-line
              tax={tax}
            />
          </Flex>
        </Col>

        <Col span={10}>
          <TaxPreview
            // @ts-expect-error-next-line
            file={tax ? tax.files[0] : null}
          />
        </Col>
      </Row>
    </Flex>
  );
};

export default TaxesDetails;
