import styled from '@emotion/styled';

import { type FlexProps, Flex, Divider as DividerAntd } from 'antd';

export const Container = styled.div`
  width: 300px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  border-radius: 0 10px 10px 0;
  padding: 20px 10px 20px 20px;
`;

export const Inner = styled.div`
  padding-right: 10px;
`;

export const Details = styled.div`
  .ui-typography {
    font-weight: 500;

    &.ui-typography-secondary {
      color: ${({ theme }) => theme.colors.text400};
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
`;

export const Divider = styled(DividerAntd)`
  && {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.bgDarkGrey};
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text400};
  padding: 4px 0;

  &:hover {
    .ui-typography {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const ShowAll = styled.a`
  font-weight: 600;
  font-size: 12px;
`;

export const AssigneeContainer = styled(Flex)<FlexProps>`
  width: 220px;
`;

export const AssigneeControl = styled(Flex)<FlexProps>`
  padding: 10px 8px;
`;

export const Assignee = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.black};
  }
`;
