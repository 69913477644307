import { Params$AdminTaskController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateTask = () => {
  return useApiClientMutation<Params$AdminTaskController_create, Schemas.Task>({
    method: 'AdminTaskController_create',
  });
};

export default useCreateTask;
