import { Params$AdminCompanyFileController_updateOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminCompanyFileController_updateOneById;

const useUpdateCompanyFileById = () => {
  const file = useApiClientMutation<Params, Schemas.CompanyFile>({
    method: 'AdminCompanyFileController_updateOneById',
  });

  return file;
};

export default useUpdateCompanyFileById;
