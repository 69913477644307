import styled from '@emotion/styled';

import { Input, type InputProps } from 'antd';

type WrapperProps = {
  isParent?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: ${({ isParent }) => (isParent ? '16px' : 0)};

  .ui-avatar {
    text-transform: uppercase;
  }
`;

export const Field = styled(Input)<InputProps>`
  flex: 1;
`;
