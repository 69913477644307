import { Params$AdminTaskFileController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useUploadTaskFile = () => {
  return useApiClientMutation<
    Params$AdminTaskFileController_create,
    Schemas.TaskFile
  >({
    method: 'AdminTaskFileController_create',
  });
};

export default useUploadTaskFile;
