
import { Params$AdminIncorporationController_failKyc } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$AdminIncorporationController_failKyc;

const useFailKYCIncorporation = () => {
  const incorporation = useApiClientMutation<Params, Schemas.Incorporation>({
    method: 'AdminIncorporationController_failKyc',
  });

  return incorporation;
};

export default useFailKYCIncorporation;
