import { FC, useState } from 'react';

import { List } from 'antd';

import { ChatLiveFile, ChatLiveFileViewer } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TaskFile = Schemas.TaskFile;

type ChatLiveFilesListProps = {
  dataSource: TaskFile[];
  light?: boolean;
  selectedFile?: TaskFile | null;
  selectedMode?: boolean;
  onSelect?: (file: TaskFile) => void;
  onRemove?: (id: string) => void;
};

const ChatLiveFilesList: FC<ChatLiveFilesListProps> = ({
  dataSource,
  light,
  selectedFile,
  selectedMode,
  onSelect,
  onRemove,
}) => {
  const [fileViewerVisible, setFileViewerVisible] = useState(false);
  const [selectedFileView, setSelectedFileView] = useState<TaskFile | null>(
    null
  );

  const handleViewFile = (file: TaskFile) => {
    setSelectedFileView(file);
    setFileViewerVisible(true);
  };

  return (
    <S.Container>
      <ChatLiveFileViewer
        open={fileViewerVisible}
        file={selectedFileView as TaskFile}
        onCancel={() => setFileViewerVisible(false)}
      />

      <List
        dataSource={dataSource}
        renderItem={(file) => (
          <ChatLiveFile
            file={file}
            onSelect={() => onSelect?.(file)}
            light={light}
            selectedFile={selectedFile}
            selectedMode={selectedMode}
            onRemove={onRemove}
            onView={() => handleViewFile(file)}
          />
        )}
        locale={{
          emptyText: <div />,
        }}
      />
    </S.Container>
  );
};
export default ChatLiveFilesList;
