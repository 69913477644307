import { Link, type LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';

import { Form } from 'antd';

export const Container = styled.div`
  padding-bottom: 76px;
`;

export const TransactionId = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

export const DocumentName = styled(Link)<LinkProps>`
  font-weight: 600;
`;

export const DocumentDownload = styled.a`
  line-height: 1;
  vertical-align: bottom;
`;

export const FormItemClear = styled(Form.Item)`
  margin-bottom: 0;
`;
