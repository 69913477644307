import { FC, useState } from 'react';

import { Button, Flex } from 'antd';

import { SendRequestModal } from '@entities';

import { Parameter$AdminCommentController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Status = Schemas.Transaction['adminStatus'];

type Parameters = Parameter$AdminCommentController_findAll;

type StatusProcessBarProps = {
  entityId: string;
  entityType: Parameters['entityType'];
  status: Status;
  onPause?: () => void;
  onProcess?: () => void;
  onFinish?: () => void;
  onRequest?: () => void;
};

const StatusProcessBar: FC<StatusProcessBarProps> = ({
  entityType,
  entityId,
  status,
  onPause,
  onProcess,
  onFinish,
  onRequest,
}) => {
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);

  if (status === 'processed') {
    return null;
  }

  return (
    <>
      <SendRequestModal
        open={isOpenRequestModal}
        // @ts-expect-error-next-line
        entityType={entityType}
        entityId={entityId}
        onRequest={onRequest}
        onCancel={() => setIsOpenRequestModal(false)}
      />

      <S.Container>
        {status === 'ready' && (
          <Flex flex={1} justify="flex-end">
            <Button type="primary" size="large" onClick={onProcess}>
              Start processing
            </Button>
          </Flex>
        )}

        {status === 'processing' && (
          <Flex flex={1} justify="space-between">
            <Button size="large" onClick={onPause}>
              Set on pause
            </Button>

            <Flex gap={16}>
              <Button size="large" onClick={() => setIsOpenRequestModal(true)}>
                Send a request
              </Button>

              <Button type="primary" size="large" onClick={onFinish}>
                Finish processing
              </Button>
            </Flex>
          </Flex>
        )}

        {status === 'paused' && (
          <Flex flex={1} justify="flex-end">
            <Flex gap={16}>
              <Button size="large" onClick={() => setIsOpenRequestModal(true)}>
                Send a request
              </Button>

              <Button type="primary" size="large" onClick={onProcess}>
                Continue
              </Button>
            </Flex>
          </Flex>
        )}

        {status === 'requested_changes' && (
          <Flex flex={1} justify="flex-end">
            <Button type="primary" size="large" onClick={onProcess}>
              Start processing
            </Button>
          </Flex>
        )}
      </S.Container>
    </>
  );
};

export default StatusProcessBar;
