import { Params$AdminBillingPeriodController_updateOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useUpdateBillingPeriod = () => {
  return useApiClientMutation<
    Params$AdminBillingPeriodController_updateOneById,
    Schemas.BillingPeriod
  >({
    method: 'AdminBillingPeriodController_updateOneById',
  });
};

export default useUpdateBillingPeriod;
