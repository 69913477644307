import { FC } from 'react';

import { colors } from '@theme';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Status = Schemas.Transaction['adminStatus'];

type StatusTagProps = {
  status: Status;
};

type ParamsByStatus = {
  label: string;
  bgColor: string;
  textColor: string;
};

const getParamsByStatus = (status: Status): ParamsByStatus => {
  switch (status) {
    case 'ready':
      return {
        label: 'Ready to process',
        bgColor: colors.blueLight,
        textColor: colors.link,
      };

    case 'processing':
      return {
        label: 'Processing',
        bgColor: colors.yellowLight,
        textColor: colors.yellow,
      };

    case 'paused':
      return {
        label: 'On pause',
        bgColor: colors.bgGrayLight,
        textColor: colors.gray,
      };

    case 'requested_changes':
      return {
        label: 'Requested client',
        bgColor: colors.redLight,
        textColor: colors.red,
      };

    case 'processed':
      return {
        label: 'Processed',
        bgColor: colors.greenLight,
        textColor: colors.success,
      };
  }
};

const StatusTag: FC<StatusTagProps> = ({ status }) => {
  if (!status) {
    return null;
  }

  const { label, bgColor, textColor } = getParamsByStatus(status);

  return (
    <S.Status bgColor={bgColor} textColor={textColor}>
      {label}
    </S.Status>
  );
};

export default StatusTag;
