import { useMemo } from 'react';

import { Params$AdminCompanyController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

type Params = Params$AdminCompanyController_findOneById;

type CompanyProps = {
  params: Params['parameter'];
};

const useGetCompanyById = ({ params }: CompanyProps) => {
  const {
    data: company,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Company>({
    method: 'AdminCompanyController_findOneById',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      company,
      loading,
      refetch,
    }),
    [company, loading]
  );
};

export default useGetCompanyById;
