import { useMemo } from 'react';

import { Params$AdminCommentController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

type Params = Params$AdminCommentController_findAll;

type ChatCommentsProps = {
  params: Params['parameter'];
};

const useGetChatCommentsList = ({ params }: ChatCommentsProps) => {
  const {
    data: comments,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Comment[]>({
    method: 'AdminCommentController_findAll',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      comments: comments || [],
      loading,
      refetch,
    }),
    [comments, loading]
  );
};

export default useGetChatCommentsList;
