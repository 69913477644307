import { Params$AdminBillingPeriodController_delete } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useRemoveBillingPeriod = () => {
  return useApiClientMutation<
    Params$AdminBillingPeriodController_delete,
    Schemas.BillingPeriod
  >({
    method: 'AdminBillingPeriodController_delete',
  });
};

export default useRemoveBillingPeriod;
