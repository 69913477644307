import { Params$AdminTaskController_updateOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useUpdateTask = () => {
  return useApiClientMutation<
    Params$AdminTaskController_updateOneById,
    Schemas.Task
  >({
    method: 'AdminTaskController_updateOneById',
  });
};

export default useUpdateTask;
