import { FC } from 'react';

import { type InputProps, type ButtonProps, Button } from 'antd';

import { IconAttach } from '@assets';

import * as S from './styled';

type ChatLiveFormProps = {
  selectedId?: string;
  fieldParams?: InputProps;
  buttonParams?: ButtonProps;
  onAttach?: () => void;
  onChange: (value: string) => void;
  onSend: () => void;
};

const ChatLiveForm: FC<ChatLiveFormProps> = ({
  selectedId,
  fieldParams,
  buttonParams,
  onAttach,
  onChange,
  onSend,
}) => (
  <S.Container align="center" gap={16}>
    {onAttach && (
      <S.AttachFile onClick={onAttach} disabled={!selectedId}>
        <IconAttach />
      </S.AttachFile>
    )}

    <S.Field
      variant="borderless"
      size="large"
      {...fieldParams}
      disabled={!selectedId}
      onChange={(e) => onChange(e.target.value)}
    />

    <Button type="primary" onClick={onSend} size="large" {...buttonParams}>
      Send
    </Button>
  </S.Container>
);

export default ChatLiveForm;
