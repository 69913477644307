import styled from '@emotion/styled';

import { Flex, type FlexProps } from 'antd';

type TaskProps = {
  selected: boolean;
};

export const Task = styled(Flex)<FlexProps & TaskProps>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.bgDarkGrey : theme.colors.white};
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  padding: 16px;
  position: relative;
`;

export const Title = styled.div`
  font-weight: 600;
  flex: 1;
`;

export const CompanyName = styled.div`
  width: 160px;
  color: ${({ theme }) => theme.colors.textBlue400};
  font-size: 12px;
`;

export const Type = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;

export const Count = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;
